import styled from 'styled-components';

const DashboardWrapper = styled.div`
.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inner-content{
    display:flex;
    justify-content:flex-end;
    position: relative;
    margin-bottom:16px;
  }
}
.heading{
  margin-top:0px;
  margin-bottom:16px;
}
.info-box{
  background-color: ${({ theme }) => theme.colors.whiteColor};
  padding:24px;
  display:flex;
  gap:24px;
  margin-bottom:16px;
  @media(max-width:1200px){
    flex-wrap:wrap
  }

  .info{
    background-color: ${({ theme }) => theme.colors.clickAbleLight};
    border-radius:8px;
    padding:20px 16px 20px 16px;
    display:flex;
    flex-direction:column;
    gap:8px;
    min-width:249px;
    @media(max-width:1200px){
    min-width:100%;
  }
    span:first-child{
      font-size: ${({ theme }) => theme.fonts.fsBody};
      line-height:18px;
      color:#797979;
      .tooltip{
        padding-left:4px;
        cursor:pointer;
      }
      
    }
    span:nth-child(2){
      color: ${({ theme }) => theme.colors.secondaryColor};
      font-size: ${({ theme }) => theme.fonts.baseFontSizeH2};
      line-height:32px;
      font-weight:700;
    }

  }
}
.statistics-box{
  background-color: ${({ theme }) => theme.colors.whiteColor};
  padding:24px;
  h3{
    font-size:20px;
    color: ${({ theme }) => theme.colors.secondaryColor};
    margin:0px;
  }
}
.graph-box{
  border:1px solid ${({ theme }) => theme.colors.visualLight};
  border-radius:8px;
  padding:24px;
  .inner-content{
    display:flex;
  justify-content:flex-end;
  position: relative;
  margin-bottom:24px;
  }
  .rdrDateRangePickerWrapper{

  }
}
.data-range-box{
  background-color: ${({ theme }) => theme.colors.whiteColor};
  padding:24px;
  position: absolute;
    top: 66px;
    z-index: 9999;
    right: 0px;
    overflow: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .button-box{
      display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    position: absolute;
    width: 70%;
    margin-left: 25%;

    }
}
.rdrDefinedRangesWrapper{
  margin-top: -62px;
}
`;

export default DashboardWrapper;
