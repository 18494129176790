import React, {
  ReactNode
} from 'react';

import Button from '../button';
import ModalWrapper from './modal.style';
import Loader from '../loader';

interface ModalProps {
  title?: string | any | undefined;
  open?: boolean | undefined;
  onCancelClick?: () => void;
  onSubmitClick?: () => void;
  children: ReactNode | undefined;
  width?: number | string | undefined;
  okText?: string | undefined;
  cancelText?: string | undefined;
  saveText?: string | undefined,
  footer?: boolean | undefined,
  saveButtonWidth?: string | undefined,
  loading?: boolean;
  centered?: boolean;
  style?: {
    top?: number;
    left?: number;
    padding?: number;
  }
}

const Index: React.FC<ModalProps> = (props) => {
  const {
    title,
    open,
    children,
    width,
    okText,
    footer,
    cancelText,
    saveText,
    onCancelClick,
    onSubmitClick,
    saveButtonWidth,
    loading,
    centered = true,
    style
  } = props;

  return (
    <ModalWrapper
      title={title}
      open={open}
      footer={false}
      width={width}
      okText={okText}
      centered={centered}
      style={style}
    >
      {children}
      {footer
        && (
          <div className="modal-footer">
            {loading && <Loader />}
            <Button
              text={cancelText?.toString() || ''}
              width="97px"
              type="default"
              onClick={onCancelClick}
            />
            {
              saveText && (
                <Button
                  text={saveText?.toString() || ''}
                  type="primary"
                  width={saveButtonWidth || '97px'}
                  onClick={onSubmitClick}
                />
              )
            }
          </div>
        )}

    </ModalWrapper>
  );
};

export default Index;
