import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Input from '../../../components/input';

import { IsLengthValidForWishNotListed } from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  WISH_NOT_LISTED_ERROR_MESSAGE
} from '../../../constants';

interface WishListedModalProps {
  onCancel?: (
    setFormData: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>
  ) => void;
  onSubmit?: (
    message: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>
  ) => void;
  open?: boolean;
  title?: string;
  loading?: boolean;
  saveText?: string;
}

const WishListedModal: React.FC<WishListedModalProps> = ({
  onCancel,
  onSubmit,
  open,
  title,
  loading,
  saveText
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<{ message: string; }>({ message: '' });

  const [formHelperText, setFormHelperText] = useState<{ message: string; }>({ message: '' });

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'message' && !IsLengthValidForWishNotListed({
      value,
      lowerLimit: 100,
      upperLimit: 250
    })) {
      errors[fieldName] = t(WISH_NOT_LISTED_ERROR_MESSAGE.LENGTH_RANGE);
    } else {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName] as string
    });
  };

  const handleSubmit = () => {
    const messageError = validateField('message', formData.message).message;

    if (messageError) {
      setFormHelperText({
        message: messageError || ''
      });

      return;
    }

    onSubmit?.(formData.message, setFormData, setFormHelperText);
  };

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => onCancel?.(setFormData, setFormHelperText)}
      onSubmitClick={handleSubmit}
      saveText={saveText}
      width={325}
      saveButtonWidth="106px"
      open={open}
      title={title}
    >
      {loading && <Loader />}
      <div className="modal-area">
        <Input
          rows={4}
          type="textarea"
          placeholder={t('enter')}
          label={t('type_something')}
          value={formData.message}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange('message', e.target.value)}
          helperText={formHelperText.message}
        />
      </div>
    </Modal>
  );
};

export default WishListedModal;
