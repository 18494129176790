import LoaderWrapper from './style';

const Loader = () => (
  <LoaderWrapper>
    <div className="image-container">
      <div className="blurred-background" />
      <img
        src="https://i.ibb.co/k0T5qwR/Whats-App-Image-2024-10-22-at-5-43-05-PM-removebg-preview.png"
        alt=""
        className="fade-image"
      />
    </div>
  </LoaderWrapper>
);

export default Loader;
