import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  GetAllNotificationsData,
  MarkNotificationsAsReadPayload,
  NotificationState
} from '../types/notification';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: NotificationState = {
  getAllNotificationsLoading: false,
  getAllNotificationsSuccess: false,
  notifications: [],
  totalNotifications: 0,
  markNotificationsAsReadLoading: false,
  markNotificationsAsReadSuccess: false,
  unreadNotificationsCount: 0,
  notifyMessage: '',
  notifyType: '',
  notify: false,
  isNotificationPopoverOpenedBySidebar: false,
  isNotificationSettingsActive: false
};

export const GetAllNotifications = createAsyncThunk(
  'notification/get-all-notifications',
  async (data: GetAllNotificationsData, { rejectWithValue }) => {
    try {
      const {
        skip,
        limit
      } = data;
      const response = await axios.get('/notification/get-all-notifications', {
        params: {
          skip,
          limit
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const MarkNotificationsAsRead = createAsyncThunk(
  'notification/mark-notifications-as-read',
  async (data: MarkNotificationsAsReadPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/notification/mark-notifications-as-read', data);

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    SetNotificationState(state, action: PayloadAction<{
      field: keyof NotificationState;
      value: NotificationState[keyof NotificationState];
    }>) {
      const updateNotification = <T extends keyof NotificationState>(field: T, value: NotificationState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateNotification(field, value as NotificationState[keyof NotificationState]);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllNotifications.pending, (state) => {
      state.getAllNotificationsLoading = true;
      state.getAllNotificationsSuccess = false;
      state.notify = false;
    })
      .addCase(GetAllNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        state.getAllNotificationsLoading = false;
        state.getAllNotificationsSuccess = true;
        state.notifications = action.payload.data.notifications;
        state.unreadNotificationsCount = action.payload.data.unreadNotificationsCount;
        state.notify = false;
        state.totalNotifications = action.payload.data.totalNotifications;
      })
      .addCase(GetAllNotifications.rejected, (state) => {
        state.getAllNotificationsLoading = false;
        state.getAllNotificationsSuccess = false;
        state.notify = true;
        state.notifyMessage = 'An error occurred!';
        state.notifyType = 'error';
      })
      .addCase(MarkNotificationsAsRead.pending, (state) => {
        state.markNotificationsAsReadLoading = true;
        state.markNotificationsAsReadSuccess = false;
        state.notify = false;
      })
      .addCase(MarkNotificationsAsRead.fulfilled, (state, action: PayloadAction<any>) => {
        state.markNotificationsAsReadLoading = false;
        state.markNotificationsAsReadSuccess = true;
        state.notify = false;
        state.unreadNotificationsCount = action.payload.data.unreadNotificationsCount;
      })
      .addCase(MarkNotificationsAsRead.rejected, (state) => {
        state.markNotificationsAsReadLoading = false;
        state.markNotificationsAsReadSuccess = false;
        state.notify = false;
      });
  }
});

const { actions } = notificationSlice;

export const { SetNotificationState } = actions;

export default notificationSlice.reducer;
