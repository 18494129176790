import styled from 'styled-components';

const AdvertisingFormatWrapper = styled.div`
.card-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wish-list-btn {
    display: flex;
    flex-direction: row-reverse;
    grid-gap: 4px;
}
}
.compare-plans-button-div {
    margin-top: 20px;
}
.credit-coin {
    height: 15px;
    width: 22px;
}
`;
export default AdvertisingFormatWrapper;
