import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Heading from './heading-box';

import DinA5 from '../../../../assets/images/Template-Creation-Basic-1.jpg';
import DinA4 from '../../../../assets/images/Template-Creation-Branding-1.jpg';
import Maxi from '../../../../assets/images/Template-Creation-Plus-1.jpg';
import ChatInfo from '../../../../assets/images/chat-info.svg';
import ChooseRibbon from '../../../../assets/images/badge_most_popular.svg';
import BasicCredit from '../../../../assets/images/basic-ohne-schatten.png';
import PlusCredit from '../../../../assets/images/plus-ohne-schatten.png';
import BrandingCredit from '../../../../assets/images/branding-ohne-schatten.png';

import Button from '../../../../components/button';

import {
  AddTemplate as AddTemplateInterface
} from '../../../../redux/types/template';
import { TemplateState } from '../../../../redux/types/template';
import { UserState } from '../../../../redux/types/user';

import { DrawerWrapper } from '../drawer-style';
import ChoosePlanWrapper from './style';

import {
  CHOOSE_PLAN_VALUES,
  ADVERTISEMENT_FORMAT_VALUES,
  ENVELOPE_FORMAT_VALUES,
  LETTER_STYLE_VALUES,
  PLAN_TYPES,
  LETTER_TYPE_VALUES
} from '../../../../constants';

interface ChoosePlanProps {
  addTemplate: AddTemplateInterface;
  setAddTemplate: (value: AddTemplateInterface) => void;
  handleNext: () => void;
  templateAction?: string;
  handleComparePlanClick: () => void;
  setDisableNext?: (value: boolean) => void;
}

interface ChoosePlanItem {
  image: string;
  id: string;
  title: string;
  secondTitle: string;
  caption: string;
  description: string;
}

const ChoosePlan: React.FC<ChoosePlanProps> = ({
  addTemplate,
  setAddTemplate,
  handleNext,
  templateAction,
  handleComparePlanClick,
  setDisableNext
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isTemplateSheetAdded,
    isTemplateUpdated,
    templateSheets
  } = useSelector((state: { template: TemplateState }) => state.template);

  const { user: userData } = useSelector((state: { user: UserState }) => state.user);

  const [comparePlansClicked, setComparePlansClicked] = React.useState(false);

  const plans = [{
    image: DinA5,
    id: CHOOSE_PLAN_VALUES.BASIC,
    title: t('basic') || CHOOSE_PLAN_VALUES.BASIC,
    secondTitle: t('basic_second_heading') || CHOOSE_PLAN_VALUES.BASIC,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.BASIC
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}`
      : '0',
    description: t('basic_message'),
    showInfoWithTitle: false
  }, {
    image: DinA4,
    id: CHOOSE_PLAN_VALUES.BRANDING,
    title: t('branding') || CHOOSE_PLAN_VALUES.BRANDING,
    secondTitle: t('branding_second_heading') || CHOOSE_PLAN_VALUES.BRANDING,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.BRANDING
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}`
      : '0',
    description: t('branding_message'),
    showInfoWithTitle: false
  }, {
    image: Maxi,
    id: CHOOSE_PLAN_VALUES.BIG,
    title: t('plus') || CHOOSE_PLAN_VALUES.BIG,
    secondTitle: t('big_second_heading') || CHOOSE_PLAN_VALUES.BIG,
    caption: userData?.purchasedBundle?.planType === PLAN_TYPES.MAXI
      ? `${userData?.purchasedBundle?.credits - userData?.purchasedBundle?.creditsUsed}`
      : '0',
    description: t('big_message')
  }];

  const handlePlanOptionClick = (plan: { title: string; id: string; }) => {
    setDisableNext?.(false);
    setAddTemplate({
      ...addTemplate,
      plan: plan.id,
      // advertisementFormat: plan.id === CHOOSE_PLAN_VALUES.BASIC
      advertisementFormat: plan.id !== CHOOSE_PLAN_VALUES.BIG
        ? ADVERTISEMENT_FORMAT_VALUES.DIN_A5
        : ADVERTISEMENT_FORMAT_VALUES.Maxi,

      // envelopeColor: plan.id === CHOOSE_PLAN_VALUES.BASIC
      //   ? 'White'
      //   : (templateAction === 'edit'
      //     && addTemplate?.envelopeColor !== ''
      //     && addTemplate?.plan === plan.id
      //     ? addTemplate?.envelopeColor
      //     : ''),
      letterStyle: LETTER_STYLE_VALUES.BASIC,
      envelopeFormat: ENVELOPE_FORMAT_VALUES.DIN_C6,
      envelopeColor: 'White',
      letterType: LETTER_TYPE_VALUES.HANDWRITTEN
      // envelopeFormat: plan.id === CHOOSE_PLAN_VALUES.BASIC
      // ? ENVELOPE_FORMAT_VALUES.DIN_C6
      // : ''
    });
  };

  useEffect(() => {
    if ((isTemplateSheetAdded || isTemplateUpdated) && comparePlansClicked) {
      navigate('/payment');
    }
  }, [
    isTemplateSheetAdded,
    isTemplateUpdated
  ]);

  return (
    <ChoosePlanWrapper>
      <div className="add-scroll">
        <div className="card-header-wrapper">
          <Heading
            title={t('choose_plan')}
            // infoDetails={t('choose_format_tip')}
          />
        </div>
        <DrawerWrapper>
          <div className="d-flex">
            <div className="d-flex gap-11  add-top-space">
              {plans.map((plan: ChoosePlanItem, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={index}
                  className={addTemplate?.plan === plan.id
                    ? 'advertisee-box active'
                    : 'advertisee-box'}
                  onClick={() => handlePlanOptionClick(plan)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleNext();
                    }
                  }}
                >
                  {/* {plan.title === 'Branding'
                  && (
                  <div className="add-ribbon">
                    <img src={ChooseRibbon} alt="no-svg" />
                  </div>
                  ) } */}
                  <div className="d-flex justify-content-center">
                    <div className="credits-counter">
                      {/* <span className="success-credit credit" /> */}
                      <img
                        className="credit-coin"
                        src={plan.id === CHOOSE_PLAN_VALUES.BASIC
                          ? BasicCredit
                          : (plan.id === CHOOSE_PLAN_VALUES.BRANDING
                            ? BrandingCredit
                            : PlusCredit)}
                        alt="no-svg"
                      />
                      <p>
                        {t('credits')}
                        :
                        {` ${plan.caption}`}
                      </p>
                    </div>
                  </div>
                  <h3 className="text-center">{plan.title}</h3>
                  <img className="type" src={plan.image} alt={plan.title} />
                  <div className="d-flex bottom-space">
                    <h4 className="d-flex align-items-center">
                      {plan.secondTitle}
                    </h4>
                  </div>
                  <span className="description">
                    {plan.description}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </DrawerWrapper>
        <div className="compare-plans-button-div">
          <Button
            text={t('compare_plan')}
            type="primary"
            onClick={() => {
              setComparePlansClicked(true);
              if (templateSheets.length > 0
                && templateSheets[0].message.length > 0) {
                handleComparePlanClick();
              } else {
                navigate('/payment');
              }
            }}
          />
        </div>
      </div>
    </ChoosePlanWrapper>
  );
};

export default ChoosePlan;
