import React, {
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  PhotoProvider,
  PhotoView
} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Popover } from 'antd';

import Heading from './heading-box';

import SummaryBox from '../../../../assets/images/summary-box.svg';
import LetterReview from '../../../../assets/images/letter-review.svg';
import EditIcon from '../../../../assets/icons/edit.svg';
import EyeIcon from '../../../../assets/icons/eye-fill.svg';
// import EnvelopeReview from '../../../../assets/images/a5-letter-review.svg';

import getPreviewContent from './preview-content';

import { DrawerWrapper } from '../drawer-style';

import {
  AddTemplate,
  AddTemplateSheet,
  HelperText,
  TemplateState
} from '../../../../redux/types/template';

import Input from '../../../../components/input';

interface SummaryProps {
  addTemplate: AddTemplate;
  addTemplateHelperText: HelperText;
  onClickEditIcon?: () => void;
  templateSheets: AddTemplateSheet[];
  setStep: (value: number) => void;
  setSelectedSheet: (value: number) => void;
  setAddTemplate: (value: AddTemplate) => void;
  setAddTemplateHelperText: (value: any) => void;
  handleBuyCreditsClick: () => void;
}

const Summary: React.FC<SummaryProps> = ({
  addTemplate,
  addTemplateHelperText,
  setAddTemplate,
  setAddTemplateHelperText,
  onClickEditIcon,
  templateSheets,
  setStep,
  setSelectedSheet,
  handleBuyCreditsClick
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isTemplateSheetAdded,
    isTemplateUpdated
  } = useSelector((state: { template: TemplateState }) => state.template);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [buyCreditsClicked, setBuyCreditsClicked] = useState(false);

  const onClickEyeIcon = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  const handleTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setAddTemplate({ ...addTemplate, name: value });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'name') errors[name] = 'Template Name is required!';
    else if (!isEmpty(value) && name === 'name') {
      errors[name] = '';
    }
    setAddTemplateHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  useEffect(() => {
    if ((isTemplateSheetAdded || isTemplateUpdated) && buyCreditsClicked) {
      navigate('/payment');
    }
  }, [
    isTemplateSheetAdded,
    isTemplateUpdated
  ]);

  return (
    <>
      <div className="no-scroll">
        <Heading
          title={t('summary')}
          buttonText={t('buy_credits')}
          onButtonClick={() => {
            setBuyCreditsClicked(true);
            handleBuyCreditsClick();
          }}
        />
        <div className="template-input">
          <Input
            name="name"
            display="block"
            width="253px"
            placeholder={t('enter_template_name')}
            value={addTemplate?.name}
            className="add-employee-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleTemplateNameChange(e);
            }}
            helperText={addTemplateHelperText.name}
          />
        </div>
        <DrawerWrapper>
          <div className="internal-summary">
            <div className="image-box">
              <img src={SummaryBox} alt={t('no_summary')} />
            </div>
            <div className="information-box">
              <div className="data-box">
                <span>{t('payment_plan')}</span>
                <span>{addTemplate?.plan || '--'}</span>
              </div>
              <div className="data-box">
                <span>{t('advertisement_format')}</span>
                {/* <span>{addTemplate?.advertisementFormat || '--'}</span> */}
                <span>DIN A5</span>
              </div>
              {/* <div className="data-box">
                <span>{t('letter_style')}</span>
                <span>{addTemplate?.letterStyle || '--'}</span>
              </div> */}
              {/* <div className="data-box">
                <span>{t('letter_type')}</span>
                <span>
                  {(() => {
                    const letterType = templateSheets?.some((sheet) => sheet.sheetType === 'Handwritten')
                    && templateSheets.some((sheet) => sheet.sheetType === 'Printed')
                      ? 'Handwritten & Printed'
                      : (templateSheets?.every((sheet) => sheet.sheetType === 'Handwritten')
                        ? 'Handwritten'
                        : 'Printed');

                    return letterType;
                  })()}
                </span>
              </div> */}
              <div className="data-box">
                <span>{t('letter_preview')}</span>
                <div className="d-flex preview-box">
                  {templateSheets?.map((obj, index) => (
                    <div key={obj?.message} className="letter-space">
                      <img className="letter-image" key={obj?.message} src={LetterReview} alt={t('no_letter')} />
                      <div className="d-flex preview-icon">
                        <div
                          className="edit-icon"
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            if (addTemplate?.advertisementFormat === 'Maxi') {
                              setStep(4);
                            } else {
                              setStep(3);
                            }
                            setSelectedSheet(index);
                          }}
                          onKeyDown={onClickEditIcon}
                        >
                          <img src={EditIcon} alt={t('no_icon')} />
                        </div>
                        {/* <div className="d-flex preview-icon">
                          <Popover
                            placement="top"
                            overlayClassName="preview-box-wrapper"
                            content={getPreviewContent({ templateSheet: obj })}
                          >
                            <div
                              className="view-icon"
                              role="button"
                              tabIndex={0}
                              onClick={() => onClickEyeIcon(LetterReview)}
                              onKeyDown={() => onClickEyeIcon(LetterReview)}
                            >
                              <img src={EyeIcon} alt={t('no_icon')} />
                            </div>
                          </Popover>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* {addTemplate?.advertisementFormat !== 'Maxi' && (
                <div className="data-box add-space-from-right">
                  <span>{t('envelope_color')}</span>
                  <span>{addTemplate?.envelopeColor || '--'}</span>
                  <div className="d-flex preview-box">
                    {[1].map((i) => (
                      <div key={i} className="letter-space">
                        <img className="letter-image" key={i} src={EnvelopeReview} alt={t('no_letter')} />
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
            </div>
          </div>

        </DrawerWrapper>
      </div>
      {selectedImage && (
        <PhotoProvider>
          <PhotoView src={selectedImage}>
            <img src={`${selectedImage}`} alt="" />
          </PhotoView>
        </PhotoProvider>
      )}
    </>
  );
};

export default Summary;
