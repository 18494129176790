import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  DashboardState,
  GraphDataPayload,
  ScanQRCodePayload
} from '../types/dashboard';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: DashboardState = {
  dashboardStats: null,
  dashboardTilesData: null,
  graphData: null,
  error: null,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: '',
  success: false,
  qrCodeScanned: false
};

export const GetDashboardStats = createAsyncThunk(
  'dashboard/get-dashboard-stats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/dashboard/get-dashboard-stats');

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetGraphData = createAsyncThunk(
  'dashboard/get-graph-data',
  async (data: GraphDataPayload, { rejectWithValue }) => {
    try {
      const response = await axios.get('/dashboard/get-graph-data', {
        params: data
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const ScanQRCode = createAsyncThunk(
  'dashboard/scan-qr-code',
  async (data: ScanQRCodePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/scan-qr-code', data);

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    SetDashboardState(state, action: PayloadAction<{
      field: keyof DashboardState;
      value: DashboardState[keyof DashboardState];
    }>) {
      const updateMaxiInsert = <T extends keyof DashboardState>(field: T, value: DashboardState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateMaxiInsert(field, value as DashboardState[keyof DashboardState]);
    },
    SetDashboardNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDashboardStats.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(GetDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.dashboardStats = action.payload.data.dashboardStats;
      })
      .addCase(GetDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ScanQRCode.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(ScanQRCode.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.qrCodeScanned = action.payload.data.qrCodeScanned;
      })
      .addCase(ScanQRCode.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
    builder
      .addCase(GetGraphData.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.notify = false;
      })
      .addCase(GetGraphData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.graphData = action.payload.data.dashboardStats;
        state.dashboardTilesData = action.payload.data.dashboardTilesData;
      })
      .addCase(GetGraphData.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const {
  reducer,
  actions
} = dashboardSlice;

export const {
  SetDashboardState,
  SetDashboardNotifyState
} = actions;

export default reducer;
