import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  UpdateUserPayload,
  UserState
} from '../types/user';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: UserState = {
  loading: false,
  error: null,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  updateUserSuccess: false,
  user: {},
  getCurrentUserSuccess: false
};

export const GetCurrentUser = createAsyncThunk(
  'user/get-current-user',
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-current-user');
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateUser = createAsyncThunk(
  'user/update-user',
  async (data: UpdateUserPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/user/update-user', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SetUserState(state, action: PayloadAction<{
      field: keyof UserState;
      value: UserState[keyof UserState]
    }>) {
      const updateProduct = <T extends keyof UserState>(field: T, value: UserState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProduct(field, value as UserState[keyof UserState]);
    },
    SetUserNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCurrentUser.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
        state.getCurrentUserSuccess = false;
      })
      .addCase(GetCurrentUser.fulfilled, (state, action) => {
        state.error = null;
        state.user = action.payload.data.user;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.getCurrentUserSuccess = true;
      })
      .addCase(GetCurrentUser.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
        state.getCurrentUserSuccess = false;
      })
      .addCase(UpdateUser.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
        state.updateUserSuccess = false;
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.notify = false;
        state.updateUserSuccess = true;
        state.user = action.payload.data.updatedUser;
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = false;
        state.loading = false;
        state.updateUserSuccess = false;
      });
  }
});

const { actions } = userSlice;

export const { SetUserState, SetUserNotifyState } = actions;

export default userSlice.reducer;
