import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import Heading from './heading-box';
import WishListedModal from '../../modals/wish-listed';

import DinA5 from '../../../../assets/images/Template-Creation-Basic-1.jpg';
import DinA4 from '../../../../assets/images/Template-Creation-Branding-1.jpg';
import Maxi from '../../../../assets/images/Template-Creation-Plus-1.jpg';
import ChatInfo from '../../../../assets/images/chat-info.svg';
// import ChooseRibbon from '../../../../assets/images/badge_most_popular.svg';
import WarningIcon from '../../../../assets/icons/warning.svg';

import Button from '../../../../components/button';
import CheckModal from '../../../../components/check-modal';

import { AuthState } from '../../../../redux/types/auth';
import { SendWishNotListedEmail } from '../../../../redux/slices/template';

import {
  AddTemplate as AddTemplateInterface,
  TemplateState
} from '../../../../redux/types/template';
import { UserState } from '../../../../redux/types/user';

import { DrawerWrapper } from '../drawer-style';
import AdvertisingFormatWrapper from './style';

import {
  ADVERTISEMENT_FORMAT_VALUES,
  CHOOSE_PLAN_VALUES,
  ENVELOPE_FORMAT_VALUES,
  PLAN_TYPES
} from '../../../../constants';

interface AdvertisingFormatProps {
  addTemplate: AddTemplateInterface;
  setAddTemplate: (value: AddTemplateInterface) => void;
  handleNext: () => void;
}

interface AdvertisingItem {
  image: string;
  id: string;
  title: string;
  secondTitle: string;
  caption: string;
  description: string;
  showInfoWithTitle: boolean;
  infoMessage?: string;
  show: boolean;
}

const AdvertisingFormat: React.FC<AdvertisingFormatProps> = ({
  addTemplate,
  setAddTemplate,
  handleNext
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state: { auth: AuthState }) => state.auth);
  const { user: userData } = useSelector((state: { user: UserState }) => state.user);
  const {
    sendWishNotListedEmailLoading,
    wishNotListedEmailSent
  } = useSelector((state: { template: TemplateState }) => state.template);
  const [wishList, setWishList] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalDescription, setWarningModalDescription] = useState<string>('');

  const advertising = [{
  //   image: DinA5,
  //   id: ADVERTISEMENT_FORMAT_VALUES.DIN_A4,
  //   title: t('DIN_A4') || ADVERTISEMENT_FORMAT_VALUES.DIN_A4,
  //   secondTitle: t('DIN_A4_second_heading') || ADVERTISEMENT_FORMAT_VALUES.DIN_A4,
  //   caption: '1.90€',
  //   description: t('DIN_A4_message'),
  //   showInfoWithTitle: false,
  //   show: true
  // }, {
    image: DinA4,
    id: ADVERTISEMENT_FORMAT_VALUES.DIN_A5,
    title: t('DIN_A5') || ADVERTISEMENT_FORMAT_VALUES.DIN_A5,
    secondTitle: t('DIN_A5_second_heading') || ADVERTISEMENT_FORMAT_VALUES.DIN_A5,
    caption: '1.70€',
    description: t('DIN_A5_message'),
    showInfoWithTitle: false,
    show: true
  }, {
    image: Maxi,
    id: ADVERTISEMENT_FORMAT_VALUES.Maxi,
    title: t('big_second_heading') || ADVERTISEMENT_FORMAT_VALUES.Maxi,
    secondTitle: t('maxi_second_heading') || ADVERTISEMENT_FORMAT_VALUES.Maxi,
    caption: '2.80€',
    description: t('maxi_message'),
    showInfoWithTitle: true,
    infoMessage: t('maxi_info-message'),
    show: addTemplate?.plan === CHOOSE_PLAN_VALUES.BIG
  }];

  // const content = (
  //   <ul className="popover-list">
  //     <li>{t('wish_not_listed_tip_1')}</li>
  //     <li>{t('wish_not_listed_tip_2')}</li>
  //     <li>{t('wish_not_listed_tip_3')}</li>
  //     <li>{t('wish_not_listed_tip_4')}</li>
  //   </ul>
  // );

  const getContent = (value: string) => (
    <div className="max-width-269">
      <p>
        {value}
      </p>
    </div>
  );

  // const handleTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { value, name } = e.target;
  //   setAddTemplate({ ...addTemplate, name: value });

  //   const errors: Partial<Record<string, string>> = {};

  //   if (isEmpty(value) && name === 'name') errors[name] = `${startCase(name)} is required!`;
  //   else if (!isEmpty(value) && name === 'name') {
  //     errors[name] = '';
  //   }
  //   setAddTemplateHelperText((prevHelperText: HelperText) => ({
  //     ...prevHelperText,
  //     ...errors
  //   }));
  // };

  const handleAdvertiseClick = (advertise: { title: string; id: string; }) => {
    if (advertise.title === t('wish_another_format')) {
      setWishList(true);
    } else {
      // if (userData?.purchasedBundle?.planType === PLAN_TYPES.BASIC
      //   && (advertise.id === ADVERTISEMENT_FORMAT_VALUES.DIN_A4 || advertise.id === ADVERTISEMENT_FORMAT_VALUES.Maxi)) {
      //   setWarningModal(true);
      //   setWarningModalDescription(t('selection_does_not_match_credits_basic_description'));
      //   return;
      // }
      // if (userData?.purchasedBundle?.planType === PLAN_TYPES.BRANDING
      // && advertise.id === ADVERTISEMENT_FORMAT_VALUES.Maxi) {
      //   setWarningModal(true);
      //   setWarningModalDescription(t('selection_does_not_match_credits_branded_description'));
      //   return;
      // }
      setAddTemplate({
        ...addTemplate,
        // envelopeColor: (userData?.purchasedBundle?.planType === 'Basic' || advertise.id === 'Maxi') ? 'White' : '',
        // envelopeColor: templateAction === 'edit'
        //   ? ([CHOOSE_PLAN_VALUES.BASIC, PLAN_TYPES.MAXI].includes(addTemplate?.plan
        //     || '') ? 'White' : 'Marble')
        //   : '',
        advertisementFormat: advertise.id
        // letterStyle: addTemplate.letterStyle,
        // envelopeFormat: advertise.id === ADVERTISEMENT_FORMAT_VALUES.DIN_A5
        //   ? ENVELOPE_FORMAT_VALUES.DIN_C6
        //   : advertise.id === ADVERTISEMENT_FORMAT_VALUES.DIN_A4
        //     ? ENVELOPE_FORMAT_VALUES.DIN_LANG
        //     : advertise.id === ADVERTISEMENT_FORMAT_VALUES.Maxi
        //       ? ENVELOPE_FORMAT_VALUES.DIN_A4_MAX
        //       : ''
      });
    }
  };

  const onCancel = (
    setFormData: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      message: string;
    }>>
  ) => {
    setFormData({ message: '' });

    setFormHelperText({ message: '' });

    setWishList(false);
  };

  const sendEmail = ({ message }: { message: string; }) => {
    if (user) {
      dispatch(SendWishNotListedEmail({
        email: user.email,
        message
      }));
    }
  };

  const onSubmit = (
    message: string,
    setFormData: React.Dispatch<React.SetStateAction<{ message: string; }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{ message: string; }>>
  ) => {
    setFormData({ message: '' });

    setFormHelperText({ message: '' });

    sendEmail({ message });
  };

  useEffect(() => {
    if (wishNotListedEmailSent) {
      setWishList(false);
    }
  }, [wishNotListedEmailSent]);

  return (
    <AdvertisingFormatWrapper>
      <div className="add-scroll">
        <div className="card-header-wrapper">
          <Heading
            title={t('choose_format')}
            infoDetails={t('choose_format_tip')}
          />
          <Button
            width="165px"
            text={t('wish_another_format')}
            type="default"
            className="wish-list-btn"
            onClick={() => setWishList(true)}
          />
        </div>
        <DrawerWrapper>
          <div className="d-flex">
            <div className="d-flex gap-11  add-top-space">
              {advertising.map((advertise: AdvertisingItem, index) => (
                advertise.show && (
                  <div
                    role="button"
                    tabIndex={0}
                    key={index}
                    className={addTemplate?.advertisementFormat === advertise.id
                      ? 'advertisee-box active'
                      : 'advertisee-box'}
                    onClick={() => handleAdvertiseClick(advertise)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleNext();
                      }
                    }}
                  >
                    {/* {advertise.title === 'DIN A5'
                    && (
                    <div className="add-ribbon">
                      <img src={ChooseRibbon} alt="no-svg" />
                    </div>
                    ) } */}
                    <h3 className="text-center">{advertise.title}</h3>
                    <img className="type" src={advertise.image} alt={advertise.title} />
                    <div className="d-flex bottom-space">
                      <h4 className="d-flex align-items-center">
                        {advertise.secondTitle}
                        {
                      advertise.showInfoWithTitle && (
                        <Popover className="control-width" content={getContent(advertise.infoMessage || '')}>
                          <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
                        </Popover>
                      )
                    }
                      </h4>
                    </div>
                    <span className="description">
                      {advertise.description}
                    </span>
                  </div>
                )
              ))}
            </div>
          </div>
        </DrawerWrapper>
      </div>
      <WishListedModal
        title={t('wish_another_format')}
        saveText={t('submit')}
        open={wishList}
        onCancel={onCancel}
        onSubmit={onSubmit}
        loading={sendWishNotListedEmailLoading}
      />
      <CheckModal
        heading={t('selection_does_not_match_credits')}
        desc={warningModalDescription}
        open={warningModal}
        onCancel={() => {
          setWarningModal(false);
        }}
        icon={<img src={WarningIcon} alt={t('no_icon')} />}
        okayText="OK"
      />
    </AdvertisingFormatWrapper>
  );
};

export default AdvertisingFormat;
