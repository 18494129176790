import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/check-modal';

interface CheckProps {
  icon?: ReactNode,
  onConfirm?: () => void;
  onCancel?: () => void;
  open?: boolean;
  heading?: string;
  noDesc?: boolean;
  desc?: any;
  okayText?: string;
  width?: string;
  className?: string;
}

const Check: React.FC<CheckProps> = ({
  icon,
  onConfirm,
  onCancel,
  heading,
  open,
  noDesc,
  desc,
  okayText,
  width,
  className
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      icon={icon}
      onConfirm={onConfirm}
      onCancel={onCancel}
      open={open}
      heading={heading}
      desc={!noDesc ? t('you_wont_be_able_to_revert') : desc || ''}
      okayText={okayText}
      width={width}
      className={className}
    />
  );
};
export default Check;
