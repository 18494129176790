import styled from 'styled-components';

const ConfigurationWrapper = styled.div`
height:100%;
overflow: auto;
.info-box{
    background-color:#00B2FF0D;
    border-radius:8px;
    padding:12px;
    display:flex;
    gap:16px;
    align-items:center;
    margin-bottom:24px;
    .info-caption{
        flex-direction:column;
        gap:2px;
    }
    span{
        color:#4E4E55;
        font-size:13px;
    }
}
.written-content{
    font-family: 'Brief-adler-Regular';
    white-space: pre-line;
    min-height:338px;
    max-height:640px;
    overflow:auto;
}
.input-box{
    border:1px solid #ECEFF1;   
    border-radius:8px;
    margin-bottom:24px;
    .input-header{
           background-color:  #F2F2EC;
           padding:12px 16px;
            border-top-left-radius:8px;
             border-top-right-radius:8px;
            span{
                img{
                    margin-left:4px;
                    margin-top:4px;
                }
            }
    }
    .input-body{
        padding:16px;
        border-bottom-left-radius:8px;
        border-bottom-right-radius:8px;

        .helper-text{
            position:relative;
        }
    }
}
`;

const ProductsWrapper = styled.div`
    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export { ConfigurationWrapper, ProductsWrapper };
