import styled from 'styled-components';

interface TableWrapperProps {
  height?: string;
}

const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;

  .table-wrapper {
    font-family: 'SF Pro Display', sans-serif!important;
    height: ${(props) => (props.height ? `calc(100vh - ${props.height})` : 'calc(100vh - 195px)')};
    .ag-root-wrapper {
      border-radius: 8px 8px 0px 0px;
      border:1px solid ${({ theme }) => theme.colors.dividerColor};
    }
    .ag-header{
            border-bottom:1px solid  ${({ theme }) => theme.colors.dividerStroke};
    }
    .ag-header-row {

      .ag-header-cell {
        padding:0px 11px;
        color: ${({ theme }) => theme.colors.placeholderColor};
        font-size: ${({ theme }) => theme.fonts.baseFontSizeH5} ;
        background-color:${({ theme }) => theme.colors.whiteColor};
        border-right:1px solid  ${({ theme }) => theme.colors.dividerStroke};
        .ag-header-cell-resize{
          display:none;
        }
      }
    }
     .ag-row {
      border-bottom:1px solid  ${({ theme }) => theme.colors.dividerStroke};
      .ag-cell-focus {
        border: 1px solid transparent;
        }
      .ag-cell  {
         font-size: ${({ theme }) => theme.fonts.baseFontSizeH5};
          color:${({ theme }) => theme.colors.bodyColor};
          padding: 8px 10px;
          line-height: 14px;
          border-right:1px solid  ${({ theme }) => theme.colors.dividerStroke};
          .status-cell {
            &.status-active{
               color:${({ theme }) => theme.colors.warningColor};
            }
            &.status-planned{
              color:${({ theme }) => theme.colors.infoColor};
            }
            &.status-completed{
               color:${({ theme }) => theme.colors.successColor};
            }
            &.status-completed-grey{
              color:${({ theme }) => theme.colors.lightGrey};
           }
            &.status-archived{
               color:${({ theme }) => theme.colors.errorColor};
            }
            &.status-processed{
                color:${({ theme }) => theme.colors.processedStatusColor};
            }
            &.status-blocked{
                color:${({ theme }) => theme.colors.backgroundPrimary};
            }
            &.status-pending{
                color:${({ theme }) => theme.colors.infoColor};
            }
            &.status-can-be-started{
                color:${({ theme }) => theme.colors.canBeStartedStatusColor};
            }
          }
          .enable{
            color:${({ theme }) => theme.colors.iconLinkColor};
          }
          .disable{
            color: gray;
            cursor: not-allowed;
            opacity: 0.5;
          }
      }
        .ag-cell-first-right-pinned{
    border-left:none;
  }
    }
  }
  .ag-row-even {
    background-color: ${({ theme }) => theme.colors.ulgColor};
  }
  .ag-pinned-left-header,.ag-pinned-right-header{
    border:none;
  }
  .overlay-no-rows-template-wrapper {
    padding: 10px;
    display: block;
    text-align: center;
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
        color: #E2C786;
  }
  .ag-checkbox-input-wrapper {
    font-size: 12px;
    width: 12px;
    height: 12px;
    line-height: 12px;
  }
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value){
    align-items:flex-start
  }
`;

export default TableWrapper;
