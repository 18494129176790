import React, {
  useEffect,
  useState
} from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/button/index';

import CongratulationsImage from '../../../../assets/images/congratulations-image.svg';

import { DrawerWrapper } from '../drawer-style';

import { AddTemplate } from '../../../../redux/types/template';
import { UserState } from '../../../../redux/types/user';

import {
  CHOOSE_PLAN_VALUES,
  PLAN_TYPES
} from '../../../../constants';

interface CongratulationsProps {
  templateAction: string;
  setTemplateAction?: (value: string) => void;
  editTemplateId: string | null;
  addTemplate: AddTemplate;
}

const Congratulations: React.FC<CongratulationsProps> = ({
  templateAction,
  setTemplateAction,
  editTemplateId,
  addTemplate
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useSelector((state: { user: UserState }) => state.user);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [redirectRoute, setRedirectRoute] = useState<string>('');
  const [editTemplateIdLocal, setEditTemplateIdLocal] = useState<string | null>(editTemplateId);

  const creditsAvailable = !(((addTemplate?.plan === CHOOSE_PLAN_VALUES.BRANDING
    && user?.purchasedBundle?.planType === PLAN_TYPES.BASIC)
      || (addTemplate?.plan === CHOOSE_PLAN_VALUES.BIG
        && user?.purchasedBundle?.planType !== PLAN_TYPES.MAXI))
        || (user?.purchasedBundle?.credits - user?.purchasedBundle?.creditsUsed) <= 0);

  // const resetValues = () => {
  //   setAddTemplate(ADD_TEMPLATE_INITIAL_STATE);
  //   setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
  //   setStep(0);
  //   setSelectedSheet(0);
  // };

  useEffect(() => {
    if (isEmpty(templateAction) && redirect) {
      navigate(redirectRoute);
    }

    return () => {
      setEditTemplateIdLocal(null);
    };
  }, [templateAction]);

  return (
    <div>
      <DrawerWrapper>
        <div className="congratulations-box">
          <div className="image-box">
            <img src={CongratulationsImage} alt={t('no_summary')} />
            <h2>{t('congratulations')}</h2>
            <p className="paragraph">
              {!isEmpty(editTemplateIdLocal)
                ? (creditsAvailable
                  ? t('edited_template_message')
                  : t('edited_template_without_credits_message')
                ) : (creditsAvailable
                  ? t('added_template_message')
                  : t('added_template_without_credits_message'))}
            </p>
            { creditsAvailable ? (
              <div className="d-flex buttons-box">
                <Button
                  width="180px"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectRoute('/templates');
                    setTemplateAction?.('');
                  }}
                  text={t('ok')}
                  type="default"
                />
                <Button
                  width="181px"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectRoute('/campaigns');
                    setTemplateAction?.('');
                  }}
                  text={t('create_campaign')}
                  type="primary"
                />
              </div>
            ) : (
              <div className="d-flex buttons-box">
                <Button
                  width="181px"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectRoute('/campaigns');
                    setTemplateAction?.('');
                  }}
                  text={t('create_campaign')}
                  type="default"
                />
                <Button
                  width="181px"
                  onClick={() => {
                    setRedirect(true);
                    setRedirectRoute('/payment');
                    setTemplateAction?.('');
                  }}
                  text={t('buy_credits')}
                  type="primary"
                />
              </div>
            )}
          </div>
        </div>
      </DrawerWrapper>
    </div>
  );
};

export default Congratulations;
