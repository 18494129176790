import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import { StoreState } from '../types/store';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: StoreState = {
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  success: false,
  amazonStoreInfo: {},
  storeId: '',
  message: '',
  totalStores: 0,
  stores: [],
  store: {},
  isConnectionsPopoverOpenedBySidebar: false,
  isConnectionsSettingActive: false
};

export const GetAllStores = createAsyncThunk(
  'stores/get-all-stores',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get('/store/get-all-stores');

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetConnectedStore = createAsyncThunk(
  'stores/get-connected-store',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get('/store/get-connected-store');

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetSPAPIRefreshToken = createAsyncThunk(
  'stores/get-spapi-token',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/store/get-spapi-refresh-token', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    SetStoreState(state, action: PayloadAction<{
      field: keyof StoreState;
      value: StoreState[keyof StoreState]
    }>) {
      const updateStore = <T extends keyof StoreState>(field: T, value: StoreState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateStore(field, value as StoreState[keyof StoreState]);
    },
    SetStoreNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllStores.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(GetAllStores.fulfilled, (state, action) => {
        state.totalStores = action.payload.data.totalStores;
        state.stores = action.payload.data.stores;
        state.notifyType = 'success';
        state.notify = false;
        state.loading = false;
      })
      .addCase(GetAllStores.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'Something went wrong';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetConnectedStore.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(GetConnectedStore.fulfilled, (state, action) => {
        state.totalStores = action.payload.data.totalStores;
        state.store = action.payload.data.store;
        state.notifyType = 'success';
        state.notify = false;
        state.loading = false;
      })
      .addCase(GetConnectedStore.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'Something went wrong';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetSPAPIRefreshToken.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(GetSPAPIRefreshToken.fulfilled, (state, action) => {
        state.storeId = action.payload.data.storeId;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
        state.success = true;
        state.loading = false;
      })
      .addCase(GetSPAPIRefreshToken.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'Something went wrong';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = storeSlice;

export const { SetStoreState, SetStoreNotifyState } = actions;

export default storeSlice.reducer;
