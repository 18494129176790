import { AxiosResponse } from 'axios';
import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  AddEmployeePayload,
  AssignCustomerPayload,
  Customer,
  EditEmployeePayload,
  Employee,
  EmployeeState,
  GetAllEmployeesData,
  UpdateEmployeeStatusPayload
} from '../types/employee';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: EmployeeState = {
  customers: [],
  employeeActionLoading: false,
  employees: [],
  error: null,
  getAllEmployeesSuccess: false,
  isEmployeeAdded: false,
  isEmployeeEdited: false,
  isCustomerAssigned: false,
  isEmployeeReInvited: false,
  isStatusUpdated: false,
  isTokenExpired: false,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: '',
  success: false,
  totalEmployees: 0
};

export const AddEmployeeApi = createAsyncThunk(
  'user/add-employee',
  async (data: AddEmployeePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/add-employee', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const AssignCustomerToEmployee = createAsyncThunk(
  'user/assign-customer-to-employee',
  async (data: AssignCustomerPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/user/assign-customer-to-employee', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const EditEmployeeApi = createAsyncThunk(
  'user/update-user',
  async (data: EditEmployeePayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/user/update-user', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetAllCustomers = createAsyncThunk(
  'user/get-all-customers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-all-customers', {
        params: {}
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetAllEmployees = createAsyncThunk(
  'user/get-all-employees',
  async (data: GetAllEmployeesData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;

      const response = await axios.get('/user/get-all-employees', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const ReInviteEmployee = createAsyncThunk(
  'user/re-invite-an-employee',
  async (data: {
    userId: string | null;
  }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/re-invite-an-employee', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateEmployeeStatus = createAsyncThunk<
AxiosResponse, UpdateEmployeeStatusPayload,
{ rejectValue: {
  error: string,
  status?: number
} }
>(
  '/auth/activate-employee-verification',
  async (data: UpdateEmployeeStatusPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/auth/activate-employee-verification', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    SetEmployeeState(state, action: PayloadAction<{
      field: keyof EmployeeState;
      value: EmployeeState[keyof EmployeeState]
    }>) {
      const updateEmployee = <T extends keyof EmployeeState>(field: T, value: EmployeeState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateEmployee(field, value as EmployeeState[keyof EmployeeState]);
    },
    SetEmployeeNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddEmployeeApi.pending, (state) => {
        state.employeeActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(AddEmployeeApi.fulfilled, (state, action) => {
        state.employeeActionLoading = false;
        state.success = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
          state.employees.push(action.payload.data.employee);
        }
        state.notifyType = 'success';
        state.notify = true;
        state.isEmployeeAdded = true;
      })
      .addCase(AddEmployeeApi.rejected, (state, action) => {
        state.employeeActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(AssignCustomerToEmployee.pending, (state) => {
        state.employeeActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(AssignCustomerToEmployee.fulfilled, (state, action) => {
        state.employeeActionLoading = false;
        state.success = true;
        state.error = null;
        state.isCustomerAssigned = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.employees = state.employees.map((employee) => {
          if (employee._id === action.payload.data.employeeId) {
            if (action.payload.data.assignmentStatus === 'assign') {
              employee.customers.push(action.payload.data.assignedCustomer);
            } else if (action.payload.data.assignmentStatus === 'unassign') {
              employee.customers = employee.customers.filter(
                (customer) => customer !== action.payload.data.assignedCustomer
              );
            }
          }
          return employee;
        });
      })
      .addCase(AssignCustomerToEmployee.rejected, (state, action) => {
        state.employeeActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(EditEmployeeApi.pending, (state) => {
        state.employeeActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
        state.isEmployeeEdited = false;
      })
      .addCase(EditEmployeeApi.fulfilled, (state, action) => {
        state.employeeActionLoading = false;
        state.success = true;
        state.error = null;
        state.isEmployeeEdited = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.employees = state.employees.map((employee) => {
          if (employee._id === action.payload.data.updatedUser._id) {
            return action.payload.data.updatedUser;
          }
          return employee;
        });
      })
      .addCase(EditEmployeeApi.rejected, (state, action) => {
        state.isEmployeeEdited = false;
        state.employeeActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllCustomers.pending, (state) => {
        state.employeeActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(GetAllCustomers.fulfilled, (state, action) => {
        state.employeeActionLoading = false;
        state.success = true;
        state.error = null;
        const payload = action.payload.data as {
          customers: Customer[];
          totalCustomers: number
        } | undefined;
        if (payload) {
          state.customers = payload.customers;
        }
      })
      .addCase(GetAllCustomers.rejected, (state, action) => {
        state.employeeActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllEmployees.pending, (state) => {
        state.loading = true;
        state.getAllEmployeesSuccess = false;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(GetAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllEmployeesSuccess = true;
        state.success = true;
        state.error = null;
        const payload = action.payload.data as { employees: Employee[]; totalEmployees: number } | undefined;
        if (payload) {
          state.employees = payload.employees;
          state.totalEmployees = payload.totalEmployees;
        }
      })
      .addCase(GetAllEmployees.rejected, (state, action) => {
        state.loading = false;
        state.getAllEmployeesSuccess = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ReInviteEmployee.pending, (state) => {
        state.employeeActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(ReInviteEmployee.fulfilled, (state, action) => {
        state.employeeActionLoading = false;
        state.success = true;
        state.error = null;
        state.isEmployeeReInvited = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(ReInviteEmployee.rejected, (state, action) => {
        state.employeeActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateEmployeeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.isTokenExpired = false;
      })
      .addCase(UpdateEmployeeStatus.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.isStatusUpdated = true;
        state.isTokenExpired = false;
        state.notify = false;
      })
      .addCase(UpdateEmployeeStatus.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        if (action.payload?.error && action?.payload?.error === 'Token expired!') {
          state.isTokenExpired = true;
          state.notify = false;
        } else {
          state.notify = true;
          state.notifyType = 'error';
          const payload = action.payload as { error?: string } | undefined;
          if (payload) {
            state.notifyMessage = payload.error || 'An error occurred!';
          }
        }
      });
  }
});

const {
  reducer,
  actions
} = employeeSlice;

export const { SetEmployeeState, SetEmployeeNotifyState } = actions;

export default reducer;
