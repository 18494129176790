import styled from 'styled-components';

const CampaignsWrapper = styled.div`
.drawer-heading{
    margin: 0px;
}
.drawer-tutorial-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 54px;
}
.cursor-pointer {
    cursor: pointer;
}
`;

export default CampaignsWrapper;
