import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import Button from '../button';
import Container from '../custom-stepper/style';
import StepsData from '../custom-stepper/stepsData';
import ChooseProduct from './component/choose-product';
import ChooseTemplate from './component/choose-template';
import Congratulations from './component/congratulations';
import Configuration from './component/configuration';
import QuickTutorial from '../modal/quick-tutorial';
import Summary from './component/summary';

import Loader from '../loader';

import {
  AddAutoCampaign as AddAutoCampaignAPI,
  GetCampaignById,
  SetCampaignState,
  UpdateAutoCampaign
} from '../../redux/slices/campaign';
import { SetProductState } from '../../redux/slices/product';

import { SetOtherNotifyState } from '../../redux/slices/other';

import { CampaignState } from '../../redux/types/campaign';
import { AuthState } from '../../redux/types/auth';
import { UserState } from '../../redux/types/user';

import { CAMPAIGN_STATUS } from '../../constants';

interface AddCampaignProps {
  campaignAction: string;
  setCampaignAction: (value: string) => void;
  setEditCampaignId?: (value: string) => void;
  setProductsData?: any;
  productsData?: any;
  editCampaignId?: string
  setStep: (type: number) => void;
  step: number;
  videoPlayerModal?: boolean;
  setVideoPlayerModal?: (value: boolean) => void;
  videoPlayerModalLink?: string;
  setVideoPlayerModalLink?: (value: string) => void;
  setIsLoginFirstTime?: (value: boolean) => void;
}

interface HelperText {
  [key: string]: string | undefined;
}

const AddAutoCampaign: React.FC<AddCampaignProps> = ({
  editCampaignId,
  campaignAction,
  setCampaignAction,
  setEditCampaignId,
  setProductsData,
  setStep,
  step,
  productsData,
  videoPlayerModal,
  setVideoPlayerModal,
  videoPlayerModalLink,
  setVideoPlayerModalLink,
  setIsLoginFirstTime
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getCampaignLoading,
    clonedCampaign,
    loading,
    newCampaign,
    currentCampaign,
    isCampaignUpdated
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const { user } = useSelector((state: { auth: AuthState }) => state.auth);

  const { user: currentUser } = useSelector((state: { user: UserState }) => state.user);

  const stepsData = [{
    content: (
      <>
        <p>{t('step_1')}</p>
        <p>{t('choose_products')}</p>
      </>
    )
  }, {
    content: (
      <>
        <p>{t('step_2')}</p>
        <p>{t('choose_letter_style')}</p>
      </>
    )
  }, {
    content: (
      <>
        <p>{t('step_3')}</p>
        <p>{t('configurations')}</p>
      </>
    )
  }, {
    content: (
      <>
        <p>{t('step_4')}</p>
        <p>{t('summary')}</p>
      </>
    )
  }];

  const [campaignHelperText, setCampaignHelperText] = useState<HelperText>({});

  const handleBack = () => {
    if (step === 0) {
      setCampaignAction('');
      setProductsData([]);
      setEditCampaignId?.('');
      dispatch(SetProductState({
        field: 'selectedProductIds',
        value: []
      }));
      dispatch(SetCampaignState({
        field: 'clonedCampaign',
        value: {}
      }));

      if (!isEmpty(newCampaign)) {
        const tempCampaign = {
          ...newCampaign,
          status: CAMPAIGN_STATUS.DRAFT
        };

        if (campaignAction === 'add') {
          dispatch(AddAutoCampaignAPI({ newCampaign: tempCampaign }));
        } else if (campaignAction === 'edit') {
          if (JSON.stringify(newCampaign) === JSON.stringify(currentCampaign)
              && clonedCampaign === null
              && isEmpty(clonedCampaign)) {
            dispatch(SetOtherNotifyState({
              message: t('no_changes_made'),
              type: 'info'
            }));
          } else if (user?.role !== 'employee') {
            dispatch(UpdateAutoCampaign({ newCampaign: tempCampaign }));
          }
        }

        dispatch(SetCampaignState({
          field: 'newCampaign',
          value: {}
        }));
      }

      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: {}
      }));
    }

    setCampaignHelperText({});
    setStep(step - 1);
  };

  const handleNext = () => {
    if (step === 0) {
      setProductsData([]);
    } else if (step === 1 && !newCampaign?.template?.id && !clonedCampaign?.templateId) {
      const errors: any = { templateId: '' };
      errors.templateId = t('template_is_required');

      setCampaignHelperText({
        ...errors
      });

      return;
    } else if (step === 2) {
      const {
        timing = 0,
        letterIntervalFrequency = 0,
        lettersQuantity = 0
      } = newCampaign;

      if (!timing || !letterIntervalFrequency || letterIntervalFrequency <= 1 || !lettersQuantity) {
        const errors: any = {
          timing: '',
          letterIntervalFrequency: ''
        };

        if (!timing) {
          errors.timing = t('timing_is_required_or_greater_than_0');
        }
        if (!letterIntervalFrequency) {
          errors.letterIntervalFrequency = t('frequency_is_required_or_greater_than_0');
        } else if (letterIntervalFrequency <= 1) {
          errors.letterIntervalFrequency = t('frequency_error_note');
        }
        if (!lettersQuantity) {
          errors.lettersQuantity = t('letters_quantity_is_required_or_greater_than_0');
        }

        setCampaignHelperText({
          ...errors
        });

        return;
      }
    } else if (step === 3) {
      const { name } = newCampaign;
      if (!name) {
        const errors: any = { name: '' };
        errors.name = t('campaign_name_is_required');

        setCampaignHelperText({
          ...errors
        });

        return;
      }

      if (campaignAction === 'add') {
        dispatch(AddAutoCampaignAPI({ newCampaign }));
      } else if (campaignAction === 'edit') {
        if (JSON.stringify(newCampaign) === JSON.stringify(currentCampaign)
            && clonedCampaign === null
            && isEmpty(clonedCampaign)) {
          dispatch(SetOtherNotifyState({
            message: t('no_changes_made'),
            type: 'info'
          }));
          return;
        }
        dispatch(UpdateAutoCampaign({ newCampaign }));
      }

      dispatch(SetCampaignState({
        field: 'campaigns',
        value: []
      }));
    }

    setStep(step + 1);
  };

  const handleVideoPlayer = () => {
    setVideoPlayerModal?.(true);
    setIsLoginFirstTime?.(false);

    if (currentUser?.language === 'en') {
      setVideoPlayerModalLink?.('https://www.youtube.com/watch?v=topBLaz4zgk');
    } else if (currentUser?.language === 'de') {
      setVideoPlayerModalLink?.('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');
    }
  };

  useEffect(() => {
    if (isCampaignUpdated) {
      dispatch(SetCampaignState({
        field: 'campaigns',
        value: []
      }));
    }
  }, [isCampaignUpdated]);

  useEffect(() => {
    if (!isEmpty(campaignAction)) {
      setStep(0);
      setCampaignHelperText({});
    } else if (isEmpty(campaignAction)) {
      dispatch(SetCampaignState({
        field: 'clonedCampaign',
        value: null
      }));
    }
  }, [campaignAction]);

  useEffect(() => {
    if (editCampaignId) {
      dispatch(GetCampaignById({ campaignId: editCampaignId }));
    }
  }, [editCampaignId]);

  return (
    <Container minWidth="240px">
      {getCampaignLoading && <Loader />}
      <div className="container">
        <ul className="stepper-li">
          {stepsData.map((obj, index) => (
            <li
              key={index}
              className={`${step === index
                ? 'current'
                : step > index
                  ? 'completed'
                  : ''}`}
            >
              <div className="content">{obj.content}</div>
            </li>
          ))}
        </ul>
      </div>
      {step === 0 && (
        <StepsData>
          <ChooseProduct
            editCampaignId={editCampaignId}
            clonedCampaign={clonedCampaign}
            setProductsData={setProductsData}
            productsData={productsData}
            step={step}
          />
        </StepsData>
      )}
      {step === 1 && (
      <StepsData>
        <ChooseTemplate
          step={step}
          editCampaignId={editCampaignId}
          clonedCampaign={clonedCampaign}
          setCampaignHelperText={setCampaignHelperText}
          campaignHelperText={campaignHelperText}
        />
      </StepsData>
      )}
      {step === 2 && (
        <StepsData>
          <Configuration
            campaignHelperText={campaignHelperText}
            setCampaignHelperText={setCampaignHelperText}
          />
        </StepsData>
      )}
      {step === 3 && (
        <StepsData>
          <Summary
            campaignHelperText={campaignHelperText}
            setCampaignHelperText={setCampaignHelperText}
          />
        </StepsData>
      )}
      {loading && <Loader />}
      {/* {step === 4 && (
        <StepsData>
          <Congratulations
            setCampaignAction={setCampaignAction}
            setProductsData={setProductsData}
            setStep={setStep}
            campaignAction={campaignAction}
            page={page}
          />
        </StepsData>
      )} */}
      {step < 4 && (
        <div className="d-flex justify-content-between">
          <Button
            type="primary"
            onClick={() => handleVideoPlayer()}
            text={t('tutorial_link_text')}
          />
          <div className="d-flex drawer-button">
            <Button
              width="100px"
              text={step === 0 ? t('cancel') : t('back_button')}
              onClick={handleBack}
              type="default"
            />
            <Button
              width="112px"
              text={step === 3 ? t('submit') : t('next_button')}
              type="primary"
              onClick={handleNext}
              disabled={!newCampaign?.productIds?.length}
            />
          </div>
        </div>
      )}
      <QuickTutorial
        open={false}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
    </Container>
  );
};

export default AddAutoCampaign;
