import React, {
  useState,
  ChangeEvent,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Popover } from 'antd';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import Input from '../input';

import FilterICon from '../../assets/icons/filter.svg';
import SearchIcon from '../../assets/icons/search.svg';
import UpIcon from '../../assets/icons/up.svg';
import DownIcon from '../../assets/icons/down.svg';
import TopArrow from '../../assets/icons/top-arrow.svg';
import DownArrow from '../../assets/icons/down-arrow.svg';

import Select from '../select';

import { GetAllTemplatesByUserId } from '../../redux/slices/template';

import { CampaignFilters } from '../../redux/types/campaign';
import {
  SortBy,
  TemplateState
} from '../../redux/types/template';

import { HandleArrowClick } from '../../utils/helpers';

interface ManualCampaignsProps {
  displayName: string;
  setSortValue: (sortValue: SortBy | null) => void;
  fieldName: string;
  showArrow: null | 'up' | 'down';
  filters: CampaignFilters;
  setFilters: (filters: CampaignFilters) => void;
  getAllCampaigns?: (applyFilters: any) => void;
  showSearch?: boolean;
  showSort?: boolean;
  templateDropdown?: boolean;
}

interface OptionsType {
  value: string
  label: string
}

const ManualCampaigns: React.FC<ManualCampaignsProps> = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    displayName,
    setSortValue,
    fieldName,
    showArrow,
    filters,
    setFilters,
    getAllCampaigns,
    showSearch = true,
    showSort = true,
    templateDropdown = false
  } = props;

  const { templates } = useSelector((state: { template: TemplateState }) => state.template);

  const [search, setSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [optionsTemplate, setOptionsTemplate] = useState<OptionsType[]>();

  const handleSortArrowClick = (direction: 'up' | 'down') => {
    HandleArrowClick(direction, showArrow, setSortValue, fieldName);
  };

  const handleFilterRemove = () => {
    setFilterValue('');
    setFilters({
      ...filters,
      searchByKeyWords: {
        ...filters?.searchByKeyWords,
        [fieldName]: ''
      }
    });

    getAllCampaigns?.({
      applyFilters: {
        ...filters,
        searchByKeyWords: {
          ...filters?.searchByKeyWords,
          [fieldName]: ''
        }
      }
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const handleApplyFilters = () => {
    const updatedFilters = {
      ...filters,
      searchByKeyWords: {
        ...filters?.searchByKeyWords,
        [fieldName]: filterValue
      }
    };

    setFilters(updatedFilters);

    if (updatedFilters.searchByKeyWords && (updatedFilters.searchByKeyWords as any)[fieldName]) {
      const fieldValue = (updatedFilters.searchByKeyWords as any)[fieldName];
      const hasNonEmptyField = typeof fieldValue === 'string' && fieldValue.trim() !== '';

      if (hasNonEmptyField) {
        getAllCampaigns?.({
          applyFilters: updatedFilters
        });
      }
    }

    setSearch(false);
  };

  const handleTemplateChange = (value: string) => {
    setFilterValue(value);
  };

  useEffect(() => {
    if (templateDropdown) {
      dispatch(GetAllTemplatesByUserId());
    }
  }, []);

  useEffect(() => {
    if (templates?.length) {
      const newList = templates.map(({
        _id,
        name
      }) => ({
        value: _id,
        label: name
      }));

      newList.splice(0, 0, {
        label: 'All',
        value: 'All'
      });

      setOptionsTemplate(newList);
    }
  }, [templates]);

  return (
    <>
      <div className="custom-header-wrapper-tb align-items-center d-flex justify-content-between w-100">
        <span>{displayName}</span>
        <div className="custom-header-item-tb d-flex gap-1">
          {showSort && (
            <div className="d-flex flex-column">
              {showArrow === 'up' ? (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSortArrowClick('down')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSortArrowClick('down');
                    }
                  }}
                >
                  <img src={TopArrow} className="pointer" alt={t('icon')} />
                </div>
              ) : (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSortArrowClick('up')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSortArrowClick('up');
                    }
                  }}
                >
                  <img src={UpIcon} className="pointer" alt={t('icon')} />
                </div>
              )}
              {showArrow === 'down' ? (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSortArrowClick('up')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSortArrowClick('up');
                    }
                  }}
                >
                  <img src={DownArrow} className="pointer" alt={t('icon')} />
                </div>
              ) : (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSortArrowClick('down')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSortArrowClick('down');
                    }
                  }}
                >
                  <img src={DownIcon} className="pointer" alt={t('icon')} />
                </div>
              )}
            </div>
          )}
          {showSearch && (
            <div
              role="button"
              tabIndex={0}
              className="pt-7"
              onClick={() => setSearch(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSearch(true);
                }
              }}
            >
              {filters?.searchByKeyWords
                && filters?.searchByKeyWords[fieldName as keyof CampaignFilters['searchByKeyWords']] === ''
                ? (
                  fieldName !== 'templateName'
                    ? <img src={SearchIcon} className="pointer" alt={t('no_search')} />
                    : <img src={FilterICon} className="pointer" alt={t('no_search')} />
                ) : (
                  <FaRegTimesCircle
                    className="filter-remove-icon"
                    onClick={handleFilterRemove}
                  />
                )}
            </div>
          )}
        </div>
      </div>
      <Popover
        content={(
          <div>
            {templateDropdown
              ? (
                <Select
                  width="237px"
                  placeholder="Template"
                  value={filterValue}
                  onChange={(value) => handleTemplateChange(
                    templates?.find((template) => template._id === value)?.name || ''
                  )}
                  dropDownData={optionsTemplate}
                />
              )
              : (
                <Input
                  marginBottom="12px"
                  placeholder={t('enter')}
                  value={filterValue}
                  onChange={handleInputChange}
                />
              )}
            <Button
              text={t('apply')}
              width="100%"
              onClick={handleApplyFilters}
              type="primary"
            />
          </div>
        )}
        title={t('search_by')}
        placement="bottom"
        trigger="click"
        open={search}
        onOpenChange={() => setSearch(false)}
      />
    </>
  );
};

export default ManualCampaigns;
