/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Popover,
  Tooltip
} from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { isEmpty, startCase } from 'lodash';

import Button from '../../components/button';
import Drawer from '../../components/drawer';
import Input from '../../components/input';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import { ProductSearchComponent } from '../../components/search-column-header';
import Table from '../../components/table';
import BulkEditNickNamesModal from './modals/bulk-edit-nick-names';
import QuickTutorial from '../../components/modal/quick-tutorial';
import UploadModal from './modals/upload';
import CheckModal from './modals/check';

import ConnectAmazon from './modals/connect-seller-account';
// import DrawerData from './drawer';
import DrawerData from '../../components/auto-campaign-drawer';

import EditIcon from '../../assets/icons/edit.svg';
import UploadIcon from '../../assets/icons/upload.svg';

import {
  AddAutoCampaign as AddAutoCampaignAPI,
  UpdateAutoCampaign,
  SetCampaignState
} from '../../redux/slices/campaign';
import { GetMaxiInsertByTemplateId } from '../../redux/slices/maxi';
import {
  EditNickName,
  GetAllProducts,
  SetProductState
} from '../../redux/slices/product';
import { GetConnectedStore } from '../../redux/slices/store';

import { CampaignState } from '../../redux/types/campaign';
import { SetOtherNotifyState } from '../../redux/slices/other';
import {
  Product,
  ProductState,
  ProductFilters,
  SortBy
} from '../../redux/types/product';
import { StoreState } from '../../redux/types/store';
import { UserState } from '../../redux/types/user';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  CAMPAIGN_STATUS,
  PAGE_SIZES
} from '../../constants';

interface CellRendererProps {
  content: React.ReactNode;
  product: Product;
}

const NickNameCellRenderer: React.FC<CellRendererProps> = ({
  content,
  product
}) => {
  const { t } = useTranslation();

  if (product?.nickName) {
    return (
      product?.nickName.length > 23 ? (
        <>
          <Tooltip title={product?.nickName}>
            {product?.nickName?.substring(0, 23)}
            ...
          </Tooltip>
          <Popover trigger="click" content={content} placement="left">
            <img
              src={EditIcon}
              alt={t('no_icon')}
              className="pointer"
            />
          </Popover>
        </>
      ) : (
        <div className="d-flex gap-1 align-items-center">
          {product?.nickName}
          <Popover trigger="click" content={content} placement="left">
            <img
              src={EditIcon}
              alt={t('no_icon')}
              className="pointer"
            />
          </Popover>
        </div>
      )
    );
  }

  return 'N/A';
};

NickNameCellRenderer.displayName = 'NickNameCellRenderer';

const ContentComponent: React.FC<{ product: Product }> = ({ product }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    nickName: product?.nickName || ''
  });
  const [formHelperText, setFormHelperText] = useState({
    nickName: ''
  });

  const validateField = (
    fieldName: keyof typeof formData,
    value: string
  ): Partial<typeof formHelperText> => {
    const errors: Partial<typeof formHelperText> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    }

    if (value.trim().length < 2) {
      errors[fieldName] = `${startCase(fieldName)} should have atleast 2 characters!`;
    }

    if (value.trim().length > 25) {
      errors[fieldName] = `${startCase(fieldName)} cannot exceed 25 characters!`;
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof typeof formData,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setFormData({
      ...formData,
      [fieldName]: value
    });

    setFormHelperText({
      ...formHelperText,
      [fieldName]: errors[fieldName] as string
    });
  };

  const handleSubmit = () => {
    const nickNameError = validateField('nickName', formData.nickName).nickName;

    if (nickNameError) {
      setFormHelperText({
        nickName: nickNameError
      });
      return;
    }

    if (product?.nickName === formData.nickName) {
      dispatch(SetOtherNotifyState({
        message: t('no_changes_made'),
        type: 'info'
      }));

      return;
    }

    dispatch(EditNickName({
      productIds: [product?._id],
      nickName: formData.nickName
    }));
  };

  return (
    <div>
      <h4 className="color-secondary m-0 add-bottom-space">{t('update_nickname')}</h4>
      <Input
        placeholder={t('enter')}
        marginBottom="35px"
        helperText={formHelperText.nickName}
        helperTextClass="error-text-nick-name"
        value={formData.nickName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange('nickName', e.target.value);
        }}
      />
      <Button
        type="primary"
        text={t('update')}
        width="100%"
        onClick={handleSubmit}
      />
    </div>
  );
};

ContentComponent.displayName = 'ContentComponent';

const createCellRenderer = (product: Product) => {
  const content = <ContentComponent product={product} />;
  const CellRenderer = () => <NickNameCellRenderer content={content} product={product} />;
  CellRenderer.displayName = 'CellRenderer';
  return <CellRenderer />;
};

const ProductTable: React.FC = () => {
  const { t } = useTranslation();
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  const {
    newCampaign,
    currentCampaign,
    isCampaignAdded,
    clonedCampaign
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const {
    isNickNameEdited,
    products,
    getAllProductsSuccess,
    loading,
    totalProducts
  } = useSelector((state: { product: ProductState }) => state.product);
  const { store } = useSelector((state: { store: StoreState }) => state.store);
  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [usedTemplateId, setUsedTemplateId] = useState<string | null>('');
  const [campaignCreatedModal, setCampaignCreatedModal] = useState<boolean>(false);
  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [openBulkEditNickNames, setOpenBulkEditNickNames] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(PAGE_SIZES[0]);
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [data, setData] = useState<Product[]>([]);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [upload, setUpload] = useState<boolean>(false);
  const [campaignAction, setCampaignAction] = useState<string>('');
  const [productsData, setProductsData] = useState<any>([]);
  const [step, setStep] = useState<number>(0);
  const [editCampaignId, setEditCampaignId] = useState<string>('');
  const [sellerAccount, setSellerAccount] = useState<boolean>(false);
  const [videoPlayerModalLink, setVideoPlayerModalLink] = useState<string>('');
  const [videoPlayerModal, setVideoPlayerModal] = useState<boolean>(false);

  const [filters, setFilters] = useState<ProductFilters>({
    searchByKeyWords: {
      title: '',
      sellerSku: '',
      asin: '',
      parentAsin: '',
      nickName: ''
    }
  });

  const handleSelectedRowData = (id: string, value: boolean) => {
    const selectedProducts = data.map((obj: any) => {
      if (obj._id === id) {
        return {
          ...obj,
          selected: value
        };
      }

      return { ...obj };
    });

    let productIds = selectedProducts?.filter((obj:any) => obj.selected);
    productIds = productIds.map((obj: any) => obj._id);

    setSelectedProductIds(productIds);
    setData(selectedProducts);
    dispatch(SetCampaignState({
      field: 'newCampaign',
      value: {
        ...newCampaign,
        productIds: [
          ...newCampaign?.productIds?.filter((productId: string) => productId !== id) || [],
          ...productIds
        ]
      }
    }));
  };

  const handleVideoPlayer = () => {
    setVideoPlayerModal(true);

    if (user?.language === 'en') setVideoPlayerModalLink('https://www.youtube.com/watch?v=topBLaz4zgk');
    else if (user?.language === 'de') setVideoPlayerModalLink('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');
  };

  const ProductCellRenderer = ({
    data
  }: {
    data: {
      _id: string,
      title: string,
      imageUrl: string,
      nickName: string,
      selected: boolean
    }
  }) => {
    const {
      _id: id,
      title: value,
      imageUrl
    } = data;
    const { t } = useTranslation();

    return (
      <div className="product-box">
        <input
          type="checkbox"
          checked={newCampaign?.productIds?.includes(id)}
          onChange={(e) => handleSelectedRowData(id, e.target.checked)}
        />
        <img src={imageUrl} width={30} height={30} alt={t('no_product')} />
        {value?.length > 23 ? (
          <Tooltip title={value}>
            <span className="ellipsis-added">
              {value}
            </span>
          </Tooltip>
        ) : <span className="ellipsis-added">{value}</span>}
        {/* <span className="ellipsis-added">{value}</span> */}
      </div>
    );
  };

  const getAllProducts = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;

    dispatch(GetAllProducts({
      filters: applyFilters,
      skip,
      limit,
      sortBy: sortValue || {}
    }));
  };

  const titleComponent = (
    <div className="drawer-tutorial-link d-flex align-items-center justify-content-between">
      <h5 className="drawer-heading m-0">{t('add_auto_campaign')}</h5>
      <Button
        type="default"
        onClick={() => handleVideoPlayer()}
        text={t('tutorial_link_text')}
      />
    </div>
  );

  const columnDefs = [{
    headerName: t('product_name'),
    field: 'title',
    headerComponent: ProductSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'title',
      filters,
      setFilters,
      getAllProducts,
      showArrow: sortValue?.title === 'asc'
        ? 'up'
        : sortValue?.title === 'desc'
          ? 'down'
          : null
    },
    sortable: true,
    pinned: 'left',
    minWidth: 300,
    cellRenderer: ProductCellRenderer,
    flex: 1
  }, {
    headerName: t('sku'),
    field: 'sellerSku',
    headerComponent: ProductSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'sellerSku',
      filters,
      setFilters,
      getAllProducts,
      showArrow: sortValue?.sellerSku === 'asc'
        ? 'up'
        : sortValue?.sellerSku === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('asin'),
    field: 'asin',
    headerComponent: ProductSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'asin',
      filters,
      setFilters,
      getAllProducts,
      showArrow: sortValue?.asin === 'asc'
        ? 'up'
        : sortValue?.asin === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('parent_asin'),
    field: 'parentAsin',
    headerComponent: ProductSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'parentAsin',
      filters,
      setFilters,
      getAllProducts,
      showArrow: sortValue?.parentAsin === 'asc'
        ? 'up'
        : sortValue?.parentAsin === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('nick_names'),
    field: 'nickName',
    headerComponent: ProductSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'nickName',
      filters,
      setFilters,
      getAllProducts,
      showArrow: sortValue?.nickName === 'asc'
        ? 'up'
        : sortValue?.nickName === 'desc'
          ? 'down'
          : null
    },
    pinned: 'right',
    flex: 1,
    cellRenderer: ({ data: product }: { data: Product }) => createCellRenderer(product)
  }];

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  const handleAddAutoCampaign = () => {
    if (!store) {
      setSellerAccount(true);
    } else {
      setCampaignAction('add');
    }
  };

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    getAllProducts({ applyFilters: filters });
    dispatch(GetConnectedStore({}));
  }, [
    pageLimit,
    pageNumber,
    sortValue,
    isNickNameEdited
  ]);

  useEffect(() => {
    if (products?.length) {
      setData(products);
    } else {
      setData([]);
    }
  }, [products]);

  useEffect(() => {
    if (getAllProductsSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetProductState({
        field: 'getAllProductsSuccess',
        value: false
      }));
    }
  }, [getAllProductsSuccess]);

  useEffect(() => {
    if (isCampaignAdded) {
      if (newCampaign?.status !== 'Draft') setCampaignCreatedModal(true);

      setCampaignAction('');
      setUsedTemplateId(newCampaign?.templateId as string);
      dispatch(SetCampaignState({
        field: 'isCampaignAdded',
        value: false
      }));
      dispatch(SetCampaignState({
        field: 'newCampaign',
        value: {}
      }));
      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: {}
      }));
    }
  }, [isCampaignAdded]);

  return (
    <>
      {loading && <Loader />}
      <>
        <div className="content-header">
          <h2 className="heading">{t('products')}</h2>
          <div className="button-box d-flex">
            {
            (selectedProductIds?.length > 1
              || (selectedProductIds?.length === 1 && !products.every((obj) => 'nickName' in obj))) && (
              <Button
                type="primary"
                onClick={() => setOpenBulkEditNickNames(true)}
                text={t('bulk-edit-nicknames')}
              />
            )
          }
            <Button
              type="default"
              onClick={() => setUpload(true)}
              icon={<img src={UploadIcon} alt={t('upload')} />}
              text={t('upload_bulk_nicknames')}
            />
            <Button
              type="primary"
              icon={<BsPlusCircle />}
              text={t('create_campaign')}
              onClick={() => handleAddAutoCampaign()}
            />
          </div>
        </div>
        <Table
          rowData={products}
          columnDefs={columnDefs}
          sortable
          resizable
          editable
          filter
          height="164px"
          rowHeight={50}
          headerHeight={40}
          className="custom-table"
          isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        />
        <Pagination
          totalPages={totalProducts}
          currentPage={pageNumber}
          onChange={onPageChange}
          pageSize={pageLimit}
          pageSizes={PAGE_SIZES}
        />
      </>
      <UploadModal title={t('upload_bulk_nicknames')} uploadNickname open={upload} onCancel={() => setUpload(false)} />
      <ConnectAmazon
        open={sellerAccount}
        onConfirm={() => {
          setCampaignAction('add');
          setSellerAccount(false);
        }}
        heading={t('your_seller_central_account_is_not_connected')}
      />
      <QuickTutorial
        open={false}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
      <CheckModal
        heading={t('campaign_uploaded_heading')}
        desc={t('campaign_uploaded_text')}
        open={campaignCreatedModal}
        onCancel={() => {
          setCampaignCreatedModal(false);
          dispatch(GetMaxiInsertByTemplateId({
            templateId: usedTemplateId as string
          }));
        }}
        okayText="OK"
        noDesc
      />
      <BulkEditNickNamesModal
        title={t('bulk-edit-nicknames')}
        open={openBulkEditNickNames}
        onCancel={() => {
          setOpenBulkEditNickNames(false);
          setSelectedProductIds([]);
        }}
        selectedProductIds={selectedProductIds}
      />
      <Drawer
        onClose={() => {
          setCampaignAction('');
          setEditCampaignId('');
          setStep(step - 1);
          dispatch(SetProductState({
            field: 'selectedProductIds',
            value: []
          }));
          dispatch(SetCampaignState({
            field: 'clonedCampaign',
            value: {}
          }));

          if (!isEmpty(newCampaign)) {
            const tempCampaign = {
              ...newCampaign,
              status: CAMPAIGN_STATUS.DRAFT
            };

            if (campaignAction === 'add') {
              dispatch(AddAutoCampaignAPI({ newCampaign: tempCampaign }));
            } else if (campaignAction === 'edit') {
              if (JSON.stringify(newCampaign) === JSON.stringify(currentCampaign)
                  && clonedCampaign === null
                  && isEmpty(clonedCampaign)) {
                dispatch(SetOtherNotifyState({
                  message: t('no_changes_made'),
                  type: 'info'
                }));
                return;
              }
              dispatch(UpdateAutoCampaign({ newCampaign: tempCampaign }));
            }

            dispatch(SetCampaignState({
              field: 'newCampaign',
              value: {}
            }));
          }

          dispatch(SetCampaignState({
            field: 'currentCampaign',
            value: {}
          }));
        }}
        open={campaignAction === 'add'}
        title={titleComponent}
        width="1123px"
      >
        <DrawerData
          campaignAction={campaignAction}
          setCampaignAction={setCampaignAction}
          setProductsData={setProductsData}
          productsData={productsData}
          setEditCampaignId={setEditCampaignId}
          editCampaignId=""
          setStep={setStep}
          videoPlayerModal={videoPlayerModal}
          setVideoPlayerModal={setVideoPlayerModal}
          videoPlayerModalLink={videoPlayerModalLink}
          step={step}
        />
      </Drawer>
    </>
  );
};

export default ProductTable;
