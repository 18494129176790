import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  extend,
  isEmpty
} from 'lodash';
import { BsPlusCircle } from 'react-icons/bs';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddEmployee from './modals/add-employee';
import AssignCustomer from './modals/assign-customer';
import UnArchive from './modals/info';
import ReInvite from './modals/re-invite';

import Table from '../../components/table';
import Pagination from '../../components/pagination';
import Button from '../../components/button';
import { EmployeeFilterComponent } from '../../components/filter-column-header';
import { EmployeeSearchComponent } from '../../components/search-column-header';
import ActionButtons from './action-buttons';
import Loader from '../../components/loader';

import {
  AddEmployeeApi,
  AssignCustomerToEmployee,
  EditEmployeeApi,
  GetAllCustomers,
  GetAllEmployees,
  ReInviteEmployee,
  SetEmployeeState
} from '../../redux/slices/employee';
import {
  Employee,
  EmployeeState,
  EmployeeFilters,
  SortBy
} from '../../redux/types/employee';

import {
  ApplyValidation,
  DecryptCredentials,
  EncryptCredentials
} from '../../utils/helpers';

import {
  EMPLOYEE_STATUS,
  PAGE_SIZES,
  REGEX_PATTERNS,
  USER_STATUS
} from '../../constants';

const EmployeeTable: React.FC = () => {
  const { t } = useTranslation();
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  const {
    employeeActionLoading,
    employees,
    getAllEmployeesSuccess,
    isCustomerAssigned,
    isEmployeeAdded,
    isEmployeeEdited,
    isEmployeeReInvited,
    loading,
    totalEmployees
  } = useSelector((state: { employee: EmployeeState }) => state.employee);

  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<number>(PAGE_SIZES[0]);
  const [assignCustomer, setAssignCustomer] = useState<boolean>(false);
  const [unAssignCustomer, setUnAssignCustomer] = useState<boolean>(false);
  const [unArchive, setUnArchive] = useState<boolean>(false);
  const [archive, setArchive] = useState<boolean>(false);
  const [reInvite, setReInvite] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<string | null>('');
  const [employeeEmail, setEmployeeEmail] = useState<string>('');
  const [employeeAction, setEmployeeAction] = useState<string>('');
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [data, setData] = useState<Employee[]>([]);
  const [selectHelperText, setSelectHelperText] = useState<string | undefined>('');
  const [filters, setFilters] = useState<EmployeeFilters>({
    searchByKeyWords: {
      name: '',
      email: '',
      status: '',
      signedUpAt: '',
      statusUpdatedAt: ''
    }
  });

  interface StatusCellRendererParams {
    value: string;
  }

  const addEmployeeHandler = ({
    email,
    name
  }: {
    email: string;
    name: string;
  }) => {
    if (employeeAction === 'edit') {
      const updateParams = {};

      if (!isEmpty(name)) {
        extend(updateParams, { name });
      }

      if (!isEmpty(email)) {
        extend(updateParams, { email });
      }

      dispatch(EditEmployeeApi({
        userId: employeeId,
        updateParams
      }));
    } else {
      dispatch(AddEmployeeApi({
        email,
        name
      }));
    }
  };

  const changeEmployeeStatus = (status: string) => {
    if (!isEmpty(employeeId)) {
      dispatch(EditEmployeeApi({
        userId: employeeId,
        updateParams: {
          status
        }
      }));
    }

    setEmployeeId(null);
  };

  const getStatusCellRenderer = (params: StatusCellRendererParams) => {
    const { value } = params;

    let statusClass = '';

    switch (value) {
      case USER_STATUS.ACTIVE:
        statusClass = 'status-completed';
        break;
      case USER_STATUS.ARCHIVED:
        statusClass = 'status-archived';
        break;
      case USER_STATUS.INVITED:
        statusClass = 'status-planned';
        break;
      default:
        statusClass = '';
    }

    return <span className={`status-cell ${statusClass}`}>{value}</span>;
  };

  const actionCellRenderer = (params: { data: Employee }) => {
    const { _id: employeeActionId } = params.data;
    return (
      <ActionButtons
        employeeId={employeeActionId}
        setAssignCustomer={setAssignCustomer}
        setUnAssignCustomer={setUnAssignCustomer}
        setUnArchive={setUnArchive}
        setArchive={setArchive}
        setReInvite={setReInvite}
        setEmployeeId={setEmployeeId}
        setEmployeeAction={setEmployeeAction}
        setEmployeeEmail={setEmployeeEmail}
      />
    );
  };

  const getAllEmployees = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;

    const isEmailAlreadyEncrypted = applyFilters?.searchByKeyWords?.email
    && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, applyFilters?.searchByKeyWords?.email);

    if (applyFilters?.searchByKeyWords?.email && !isEmailAlreadyEncrypted) {
      applyFilters.searchByKeyWords.email = EncryptCredentials(applyFilters?.searchByKeyWords?.email);
    }

    dispatch(GetAllEmployees({
      skip,
      limit,
      sortBy: sortValue || {},
      filters: applyFilters
    }));
  };

  const columnDefs = [{
    headerName: t('employee_name'),
    field: 'name',
    headerComponent: EmployeeSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'name',
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.name === 'asc'
        ? 'up'
        : sortValue?.name === 'desc'
          ? 'down'
          : null
    },
    minWidth: 220,
    flex: 1,
    pinned: 'left'
  }, {
    headerName: t('email'),
    field: 'email',
    headerComponent: EmployeeSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'email',
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.email === 'asc'
        ? 'up'
        : sortValue?.email === 'desc'
          ? 'down'
          : null
    },
    minWidth: 150,
    flex: 1
  }, {
    headerName: t('status'),
    field: 'status',
    cellRenderer: getStatusCellRenderer,
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'status',
      options: EMPLOYEE_STATUS,
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.status === 'asc'
        ? 'up'
        : sortValue?.status === 'desc'
          ? 'down'
          : null
    },
    minWidth: 110,
    flex: 1
  }, {
    headerName: t('sign_up_date'),
    field: 'signedUpAt',
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'signedUpAt',
      filters,
      setFilters,
      getAllEmployees,
      showFilter: false,
      showArrow: sortValue?.signedUpAt === 'asc'
        ? 'up'
        : sortValue?.signedUpAt === 'desc'
          ? 'down'
          : null
    },
    minWidth: 120,
    flex: 1
  }, {
    headerName: t('status_change_date'),
    field: 'statusUpdatedAt',
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'statusUpdatedAt',
      filters,
      setFilters,
      getAllEmployees,
      showFilter: false,
      showArrow: sortValue?.statusUpdatedAt === 'asc'
        ? 'up'
        : sortValue?.statusUpdatedAt === 'desc'
          ? 'down'
          : null
    },
    minWidth: 100,
    flex: 1
  }, {
    headerName: t('actions'),
    field: 'actions',
    pinned: 'right',
    minWidth: 140,
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  const onCancel = (
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>
  ) => {
    setFormData({
      name: '',
      email: ''
    });

    setFormHelperText({
      name: '',
      email: ''
    });

    setEmployeeAction('');
  };

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  const onSubmit = (
    email: string,
    name: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      name: string;
      email: string;
    }>>
  ) => {
    if (employeeAction !== 'edit') {
      setFormData({
        name: '',
        email: ''
      });

      setFormHelperText({
        name: '',
        email: ''
      });
    }

    addEmployeeHandler({
      email,
      name
    });
  };

  const assignCustomerHandler = (
    customer: string,
    saveText: string | undefined,
    setSelectedCustomer: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    if (!isEmpty(customer)) {
      dispatch(AssignCustomerToEmployee({
        customerId: customer,
        employeeId,
        assignmentStatus: saveText === t('assign') ? 'assign' : 'unassign'
      }));

      setSelectedCustomer(undefined);

      setSelectHelperText('');
    } else {
      setSelectHelperText(t('please_select_a_customer'));
    }
  };

  const getAllCustomers = () => {
    dispatch(GetAllCustomers());
  };

  const reInviteEmployee = () => {
    if (!isEmpty(employeeId)) {
      dispatch(ReInviteEmployee({
        userId: employeeId
      }));
    }

    setEmployeeId(null);
  };

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    getAllEmployees({ applyFilters: filters });
  }, [
    pageLimit,
    pageNumber,
    sortValue
  ]);

  useEffect(() => {
    if (employees?.length) {
      const rowData = employees?.map((employee) => ({
        ...employee,
        email: DecryptCredentials(employee.email),
        signedUpAt: new Date(employee.signedUpAt).toDateString(),
        statusUpdatedAt: new Date(employee.statusUpdatedAt).toDateString()
      }));

      setData(rowData);
    } else {
      setData([]);
    }
  }, [employees]);

  useEffect(() => {
    if (assignCustomer || unAssignCustomer) {
      getAllCustomers();
    }
  }, [assignCustomer, unAssignCustomer]);

  useEffect(() => {
    if (isEmployeeAdded) {
      setEmployeeAction('');
      getAllEmployees({ applyFilters: filters });
      dispatch(SetEmployeeState({
        field: 'isEmployeeAdded',
        value: false
      }));
    }

    if (isEmployeeEdited) {
      setEmployeeAction('');
      setArchive(false);
      setUnArchive(false);
      dispatch(SetEmployeeState({
        field: 'isEmployeeEdited',
        value: false
      }));
    }

    if (isEmployeeReInvited) {
      setReInvite(false);
      dispatch(SetEmployeeState({
        field: 'isEmployeeReInvited',
        value: false
      }));
    }

    if (isCustomerAssigned) {
      setAssignCustomer(false);
      setUnAssignCustomer(false);
      dispatch(SetEmployeeState({
        field: 'isCustomerAssigned',
        value: false
      }));
    }
  }, [
    isCustomerAssigned,
    isEmployeeAdded,
    isEmployeeEdited,
    isEmployeeReInvited
  ]);

  useEffect(() => {
    if (getAllEmployeesSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetEmployeeState({
        field: 'getAllEmployeesSuccess',
        value: false
      }));
    }
  }, [getAllEmployeesSuccess]);

  return (
    <>
      {loading && <Loader />}
      <>
        <div className="content-header">
          <h2 className="heading">{t('employees')}</h2>
          <Button
            type="primary"
            onClick={() => {
              setEmployeeAction('add');
            }}
            icon={<BsPlusCircle />}
            text={t('add_employee')}
          />
        </div>
        <Table
          rowData={data}
          columnDefs={columnDefs}
          sortable
          resizable
          editable
          filter
          height="164px"
          rowHeight={36}
          headerHeight={40}
          className="custom-table"
          isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        />
        <Pagination
          totalPages={totalEmployees}
          currentPage={pageNumber}
          onChange={onPageChange}
          pageSize={pageLimit}
          pageSizes={PAGE_SIZES}
        />
      </>
      <AssignCustomer
        open={assignCustomer}
        onCancel={() => {
          setAssignCustomer(false);
          setSelectHelperText('');
        }}
        onSave={assignCustomerHandler}
        title={t('assign_customer')}
        saveText={t('assign')}
        employeeId={employeeId}
        selectHelperText={selectHelperText}
        setSelectHelperText={setSelectHelperText}
        loading={employeeActionLoading}
      />
      <AssignCustomer
        open={unAssignCustomer}
        onCancel={() => {
          setUnAssignCustomer(false);
          setSelectHelperText('');
        }}
        onSave={assignCustomerHandler}
        title={t('unassign_customer')}
        saveText={t('unassign')}
        employeeId={employeeId}
        selectHelperText={selectHelperText}
        setSelectHelperText={setSelectHelperText}
        loading={employeeActionLoading}
      />
      <UnArchive
        open={unArchive}
        onCancel={() => setUnArchive(false)}
        heading={t('unarchive_employee_confirmation')}
        onConfirm={() => changeEmployeeStatus(USER_STATUS.ACTIVE)}
        loading={employeeActionLoading}
      />
      <UnArchive
        open={archive}
        onCancel={() => setArchive(false)}
        heading={t('archive_employee_confirmation')}
        onConfirm={() => changeEmployeeStatus('Archived')}
        loading={employeeActionLoading}
      />
      <ReInvite
        open={reInvite}
        onCancel={() => {
          setReInvite(false);
          setEmployeeId(null);
        }}
        onConfirm={() => {
          reInviteEmployee();
        }}
        desc={`${t('re-invite_employee_confirmation')} ${employeeEmail} ?`}
        heading={t('re-invite')}
        loading={employeeActionLoading}
      />
      <AddEmployee
        open={employeeAction === 'edit' || employeeAction === 'add'}
        saveText={employeeAction === 'edit' ? t('update') : t('submit')}
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={employeeAction === 'edit' ? t('edit_employee') : t('add_employee')}
        employeeId={employeeId as string}
        loading={employeeActionLoading}
      />
    </>
  );
};

export default EmployeeTable;
