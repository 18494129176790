import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  GetRightToUpdateProfilePayload,
  ProfileState,
  UpdateProfileSettingsPayload
} from '../types/profile';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: ProfileState = {
  isCurrentPasswordValid: false,
  currentPassword: '',
  isProfileUpdated: false,
  loading: false,
  success: false,
  error: null,
  notify: false,
  notifyMessage: '',
  notifyType: 'success',
  isProfilePopoverOpenedBySidebar: false,
  isProfileSettingsActive: false
};

export const GetRightToUpdateProfileSettings = createAsyncThunk(
  'profile/get-right-to-update-profile-settings',
  async (data: GetRightToUpdateProfilePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/profile/get-right-to-update-profile-settings', {
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateProfileSettings = createAsyncThunk(
  'profile/update-profile-settings',
  async (data: UpdateProfileSettingsPayload, { rejectWithValue }) => {
    try {
      const { updateParams } = data;
      const response = await axios.patch('/profile/update-profile-settings', { updateParams });
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    SetProfileState(state, action: PayloadAction<{
      field: keyof ProfileState;
      value: ProfileState[keyof ProfileState];
    }>) {
      const updateProfile = <T extends keyof ProfileState>(field: T, value: ProfileState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProfile(field, value as ProfileState[keyof ProfileState]);
    },
    SetProfileNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetRightToUpdateProfileSettings.pending, (state) => {
      state.loading = true;
      state.isCurrentPasswordValid = false;
      state.currentPassword = '';
    });
    builder.addCase(GetRightToUpdateProfileSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.notify = false;
      state.isCurrentPasswordValid = true;
      const payload = action.payload.data as { password?: string };
      if (payload) {
        state.currentPassword = payload.password || '';
      }
    });
    builder.addCase(GetRightToUpdateProfileSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
      const payload = action.payload as { error?: string };
      if (payload) {
        state.notifyMessage = payload.error || 'An error occurred!';
      }
      state.notifyType = 'error';
      state.notify = true;
      state.isCurrentPasswordValid = false;
    });
    builder.addCase(UpdateProfileSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.notify = false;
      state.success = false;
      state.isProfileUpdated = false;
    });
    builder.addCase(UpdateProfileSettings.fulfilled, (state, action) => {
      state.success = true;
      state.error = null;
      state.loading = false;
      const payload = action.payload as { message?: string } | undefined;
      if (payload) {
        state.notifyMessage = payload.message || '';
      }
      state.notifyType = 'success';
      state.notify = true;
      state.isProfileUpdated = true;
    });
    builder.addCase(UpdateProfileSettings.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.notify = true;
      state.notifyType = 'error';
      state.error = action.payload as string;
      const payload = action.payload as { error?: string };
      if (payload) {
        state.notifyMessage = payload.error || 'An error occurred!';
      }
      state.isProfileUpdated = false;
    });
  }
});

const {
  reducer,
  actions
} = profileSlice;

export const {
  SetProfileState,
  SetProfileNotifyState
} = actions;

export default reducer;
