import styled from 'styled-components';

const LoaderWrapper = styled.div`
    .image-container {
      display: grid;
      place-items: center;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      z-index: 4;
    }

    .fade-image {
      width: 150px;
      height: auto;
      opacity: 0;
      animation: fadeInOut 2s ease-in-out infinite;
    }

    .blurred-background {
      width: 100%;
      height: 100vh;
      background-color: transparent;
      backdrop-filter: blur(3px);
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }

    @keyframes fadeInOut {

      0%,
      100% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }
    }
`;
export default LoaderWrapper;
