import io from 'socket.io-client';

import { GetAllCampaigns } from '../redux/slices/campaign';
import { GetAllNotifications } from '../redux/slices/notification';
import { GetPaymentCards } from '../redux/slices/payment';
import { GetCurrentUser } from '../redux/slices/user';

const ConnectSocket = ({
  url,
  userId
}: {
  url: string;
  userId: string;
}) => {
  const socket = io(url);
  socket.on('connect', () => {
    socket.emit('add-user', userId);
  });

  return socket;
};

const DisconnectSocket = async (socket: any) => {
  socket.disconnect();
};

const HandleNotificationCreation = async (socket: any, dispatch: any) => {
  socket.on('notification-creation', () => {
    dispatch(GetAllNotifications({
      skip: 0
    }));
  });
};

const HandleCampaignProgress = async (socket: any, dispatch: any) => {
  socket.on('campaign-progress-update', () => {
    dispatch(GetAllCampaigns({
      skip: 0
    }));
  });
};

const HandleCurrentUser = async (socket: any, dispatch: any) => {
  socket.on('get-current-user', () => {
    dispatch(GetCurrentUser());
  });
};

const HandleGetPaymentCards = async (socket: any, dispatch: any) => {
  socket.on('get-payment-cards', () => {
    dispatch(GetPaymentCards());
  });
};

export {
  ConnectSocket,
  DisconnectSocket,
  HandleCampaignProgress,
  HandleCurrentUser,
  HandleGetPaymentCards,
  HandleNotificationCreation
};
