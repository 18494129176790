import styled from 'styled-components';

interface WarningModalProps {
  authInput? : string;
}

const WarningModalWrapper = styled.div<WarningModalProps>`
    width:100%;
 display: flex;
  flex-direction: column;
  align-items: center;
  &.bf-error-wrapper{
    height: 343px;
    .warning-desc {
    width: 100%;
    max-width: 100%;
     div{
      &:nth-child(1){
        height: 150px;
        overflow: auto;
        border: 1px solid #ddd;
      }
      table{
        thead{
          position: sticky;
          top: 0;
          background: #8d8d8d;
          color: #fff;
          tr{
            th{
              border-bottom: 1px solid #ddd;
            }
          }
        }
      }
     }
  }
  }
  h3 {
    text-align:center;
    font-size:20px;
    margin:16px 12px 0 12px;;
  }
  .warning-desc {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    line-height: 18px;
    display: block;
    text-align: center;
    max-width: 350px;
    margin-top:8px; 
  }
  .margin-top{
    margin-top:24px;
  }
`;
export default WarningModalWrapper;
