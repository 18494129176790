import styled from 'styled-components';

interface LiveViewCardStyleWrapperProps {
  font: string;
}

const LiveViewCardStyleWrapper = styled.div<LiveViewCardStyleWrapperProps>`
  position: relative;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px; */
      background-color: #f9f9f9;
      border: 1px solid #dfdede;
      border-radius: 9px;
      margin-top: 9px;
      overflow: hidden;

  .add-sheet-button {
    padding: 6px 21px 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid ${({ theme }) => theme.colors.dividerColor};
    &.only-pagination{
      justify-content: end;
    }
    .sheet-box {
      cursor: pointer;
      span {
        padding-left: 6px;
        font-size: ${({ theme }) => theme.fonts.baseFontSizeH5};
        padding-top: 2px;
        color: ${({ theme }) => theme.colors.iconLinkColor};
      }
    }
  }
  .second-msg-part.written-box-ba {
    width: calc(100% - 70px);
}
  .written-box-ba {
    font-family: ${(props) => props.font || 'Brief-adler-Regular'};
    white-space: pre-line;
    /* overflow: auto; */
    font-size: 23px;
    height: auto;
    &.DIN-A4-Div{
      font-size: 14px;
    }
  }
  .image-size {
    width: 100%;
    height: 100%;
  }
  .card-ui-wrapper {
    height: 100%;
    
    // padding: 22.67px;
    margin: 8px;
    margin-top: 80px;
    height: calc(100lvh - 28vh);
    background-color: #fff;
    margin-top: 8px;
    img.trash-icon {
      position: absolute;
      right: 24px;
      top: 8px;
      cursor: pointer;
    }
    .logo-wrapper {
      height: 38px;
      margin-top: -20px;
      margin-bottom: 10px;
    }
    img.logo-image {
      margin: auto;
      display: block;
      max-width: 265px;
      max-height: 38px;
    }
    h4 {
      position: absolute;
      top: 8px;
      margin: 0px;
    }
    .content-area {
      padding-top: 16px;
      padding-bottom: 8px;
    }
  }
  .qr-code-image {
    padding: 0px 8px;
    position: absolute;
    right: 20px;
    bottom: 50px;
    border: 2px solid;
    border-radius: 8px;
    width: 50px;
  }
`;
export default LiveViewCardStyleWrapper;
