import styled from 'styled-components';

interface DrawerWrapperProps {
  height?: string;
  font?: string;
}

const HeadingBoxWrapper = styled.div<DrawerWrapperProps>`
    h3{
        margin-bottom:3px;
    }
    span{
       font-size: ${({ theme }) => theme.fonts.baseFontSizeBody};
    }
`;

const DrawerWrapper = styled.div<DrawerWrapperProps>`
.info-box{
    padding:12px;
    display:flex;
    gap:16px;
    align-items:center;
}
.template-box{
  display:flex;
  align-items:center;
  gap:10px;
}
.add-space-wrapper{
  align-items: center;
  grid-gap: 8px;
  justify-content: space-between;
}
.edit-template-btn{
  margin-top: 10px;
}
.qr-code{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 13px;
    .ant-qrcode{
      border:none;
      margin-right: -9px;
    }
}
.template-input{
    margin-top: 23px;
    margin-bottom:32px;
}
.no-data-wrapper {
    margin-top: 24px;
    .no-data-bar {
    border-radius: 4px;
    border: 1px dashed var(--Text-Field, #DFDEDE);
    background: var(--Color-Light-Light, #FCFCFD);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 18.5px;
    margin-bottom: 14px;
    p {
    padding: 0px !important;
}
}
}
.file-data-wrapper{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    p{
        padding-bottom:0px !important;
    }
}
.upload-file-container{
    p{
        padding-bottom:8px;
    }
}
.helper-text {
    font-size: ${({ theme }) => theme.fonts.baseFontSizeXs};
    line-height: 12px;
    color: ${({ theme }) => theme.colors.errorColor};
      position:absolute;
}
.file-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 7px 12px;
    border: 1px dashed var(--Text-Field, #DFDEDE);
    background: var(--Color-Light-Light, #FCFCFD);
    .title {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        p {
            padding: 0px !important;
            color: var(--Color-Dark-Dark-2, #87898E);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }
.actions{
    display: flex;
    grid-gap: 8px;    
    button {
    background: transparent;
    border: none;
    cursor: pointer;
    }
}
.cursor-pointer{
cursor: pointer;
}
}
.advertisee-box{
    border:1px solid ${({ theme }) => theme.colors.textFieldColor};
    padding:16px;
    border-radius:8px;
    max-width:490px;
    flex:1;
    position: relative;
    cursor:pointer;
    &.letter-box{
        max-width:490px; 
        width: 490px;
        padding-bottom: 7px;
        

    }
    &.choose-envelope{
        min-width:307px;
    }
    &.active{
     border:1px solid ${({ theme }) => theme.colors.primaryColor};   
    }
    h4{
        margin:0;
    }
    .type{
        margin-bottom: 11px;
        width:100%;
    }
    .tooltip{
        img{
            padding-left:3px;
        }
    }
    .bottom-space{
        margin-bottom:6px;
    }
    .bottom-space-for-envelope{
            margin-bottom: -2px;
    }
    .description{
        font-size: ${({ theme }) => theme.fonts.baseFontSizeH5};
        display: inline-block;
    line-height: 14px;
    }
    .credits-counter{

      display: flex;
      align-items: center;
      height: 24px;
      border-radius: 4px;
      margin-left: 4px;
      margin-top: -4px;
      margin-bottom: -8px;
      .credit{
        font-size: ${({ theme }) => theme.fonts.baseFontSizeLabel};
        line-height: ${({ theme }) => theme.fonts.baseFontSizeXs};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        color: ${({ theme }) => theme.colors.labelColor};
        padding: 0 8px;
        border-left: 1px solid ${({ theme }) => theme.colors.dividerColor};
        display: flex;
        align-items: center;
        gap: 4px;
        &::before{
          content: '';
          display: inline-block;
          height: 12px;
          width: 12px;
          border-radius: 30px;
        }
        &:first-child{
          padding-left: 0;
          border-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
      .success-credit::before{
        background-color: ${({ theme }) => theme.colors.successColor};
      }
      .planned-credit::before{
        background-color: ${({ theme }) => theme.colors.infoColor};
      }
      .active-credit::before{
        background-color: ${({ theme }) => theme.colors.errorColor};
      }
    }
}
  .add-top-space{
    margin-top:24px;
  }
  .written-box{
        margin-top:-4px;
    .live-view{
         border:1px solid ${({ theme }) => theme.colors.textFieldColor};
        padding:12px 16px;
        border-radius:8px;
        padding-bottom:28px;
        min-height: 424px;
        h5{
            margin:0;
             font-size: ${({ theme }) => theme.fonts.baseFontSize};
             margin-bottom:20px;
        }
    }
    .live-view-with-sheet{
         border:1px solid ${({ theme }) => theme.colors.textFieldColor};
        padding:12px 16px;
        border-radius:8px;
        padding-bottom:28px;
        min-height: 428px;
        h5{
            margin:0;
             font-size: ${({ theme }) => theme.fonts.baseFontSize};
             margin-bottom:20px;
        }
    }
    .text-area-box{
        textarea{
            height: calc(100vh - 42vh);
           resize: none;
           padding:10px 11px;
        }
    }
  }
  .upload-logo{
    margin-top:15px;
     label{
    color: ${({ theme }) => theme.colors.labelColor};
    display:block;
    margin-bottom:6px;
   }
  .add-space-25{
    align-items:flex-end;
    justify-content:space-between;
   }
  .pl-5{
    padding-left:5px;
   }
  }
  .preview-content{
      border: 1px solid  ${({ theme }) => theme.colors.textFieldColor};
      margin-left:8px;
       border-radius:8px;
       position: relative;
  .preview-box{
     background-color: ${({ theme }) => theme.colors.tableHeaderColor400};
     padding:11px 8px 0 17px;
     border-top-left-radius:8px;
     border-top-right-radius:8px;
     border-bottom: 1px solid  ${({ theme }) => theme.colors.textFieldColor};
     min-height: 365px;
     .trash-icon-wrapper {
        position: absolute;
        right: 16px;
        cursor: pointer;
    }
    .trash-box{
        background-color: ${({ theme }) => theme.colors.errorColor}05; 
        width:24px;
        height:24px;
        border-radius:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:disabled;
        img{
            filter: invert(62%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(90%) contrast(91%);
        }
        &.active{
            background-color: ${({ theme }) => theme.colors.errorColor}05; 

        }
    }
    .logo-box{
      display: flex;
      justify-content: center;
      text-align:center;
      margin:15px 0px;
      img{
          width:142px;
      }
    }
    .image-size{
      width: 100%;
      height: 100%;
    }
    .resizeable-box{
      border: 1px ridge;
      display: flex;
      justify-content: center;
    }
    .written-content{
      font-family: ${(props) => props.font || 'Brief-adler-Regular'};
      white-space: pre-line;
      min-height:309px;
      max-height:640px;
      overflow:auto;
    }
    .next-preview-content .written-content {
      font-family: ${(props) => props.font || 'Brief-adler-Regular'};
    }
    .qr-code{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 13px;
        .ant-qrcode{
                border:none;
                    margin-right: -9px;
        }
    }
  }
  .add-sheet-button{
    padding:1px 21px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    .sheet-box{
        cursor:pointer;
        span{
                padding-left: 6px;
                  font-size:  ${({ theme }) => theme.fonts.baseFontSizeH5};
                  padding-top: 2px;
                  color: ${({ theme }) => theme.colors.iconLinkColor};
        }
    }
  }
  }
  .internal-summary{
        max-width: 469px;
    margin: 28px auto;
    &.internal-maxi{
            max-width: 379px;
    margin: -77px auto;
    margin-left: 434px;
    }
    .image-box{
        text-align: center;
    margin-left: 9px;
    &.maxi-image-box{
            margin-left: -10px;
    margin-top: -31px;
    }
    }
    .information-box{
        margin-top:27px;
        margin-left:16px;
        &.maxi-information-box{
                margin-top: 38px;
    margin-left: 16px
        }
        .data-box{
            display:flex;
            justify-content:space-between;
            border-bottom:1px solid ${({ theme }) => theme.colors.visualLight};
            padding-bottom: 11px;
            margin-bottom:11px;
            span:nth-child(1){
                font-size: ${({ theme }) => theme.fonts.baseFontSize};
                color:#484C56;
            }
               span:nth-child(2){
                font-size: ${({ theme }) => theme.fonts.baseFontSize};
                color:#484C56;
                font-weight:bold;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
  }
  .preview-box{
    gap:12px;
    .letter-space{
          cursor:pointer;
          position:relative;
          &:hover{
            .preview-icon{
                display:block;
                .edit-icon {
                width: 12px;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                position: absolute;
                right: 10px;
                bottom: 0;
                top: 20px;
                z-index:2;
            }
                .view-icon {
                width: 12px;
                height: 12px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                position: absolute;
                left: 10px;
                right: 0;
                bottom: 0;
                top: 20px;
            }
            }
          }
    }
   
    .preview-icon{
    position: absolute;
    top: 0px;
    left: 0px;
    gap: 7px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    .icon-box{
            background-color:${({ theme }) => theme.colors.whiteColor}; 
            width:12px;
            height:12px;
            border-radius:50px;
            display:flex;
            align-items:center;
            justify-content:center;
            img{
                width:5px;
            }

        }
    }
  }
  .congratulations-box{
    border: 1px solid ${({ theme }) => theme.colors.textFieldColor};
    border-radius: 8px;
    margin-left: 21px;
    margin-top: 34px;
    .image-box{
        display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    max-width: 498px;
    margin: 161px auto;
    h2{
        margin-top: 23px;
    margin-bottom: 11px;
    }
    .paragraph{
     text-align: center;
    max-width: 361px
    }
    }

    .buttons-box{
        margin-top: 23px;
    gap: 16px;
    }
  }
  .add-space{
    gap:16px;
  }
  .letter-note{
    margin-top:8px
  }
  .add-ribbon{
    position: absolute;
    top: -26px;
    right: -23px;
  }
`;
export { DrawerWrapper, HeadingBoxWrapper };
