import moment from 'moment';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  useSelector,
  useDispatch
} from 'react-redux';

import { Tooltip } from 'antd';
import { BsDownload } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/loader';
import Table from '../../components/table';
import Pagination from '../../components/pagination';
import { FilePageFilterComponent } from '../../components/filter-column-header';
import { FilesSearchComponent } from '../../components/search-column-header';

import {
  GetAllFiles,
  SetFileState,
  UpdateStatusOfFile
} from '../../redux/slices/file';

import {
  File,
  FileState,
  SortBy,
  FileFilters
} from '../../redux/types/files';

import { UserState } from '../../redux/types/user';

import CampaignsWrapper from '../manual-campaigns/style';

import {
  ConnectSocket,
  DisconnectSocket,
  HandleNotificationCreation
} from '../../utils/socket';

import {
  FILE_ADVERTISEMENT_FORMAT,
  FILE_ENVELOPE_COLOR,
  FILE_ENVELOPE_FORMAT,
  FILE_REPORT_STATUS,
  PAGE_SIZES
} from '../../constants';
import { GetS3ImageUrl } from '../../utils/helpers';

interface CellRendererProps {
  value: string | undefined;
}

const CustomCellRenderer:React.FC<CellRendererProps> = ({ value }) => {
  if (value) {
    return (
      value.length > 29 ? <Tooltip title={value}>{value}</Tooltip> : value
    );
  }
  return 'N/A';
};

const FilesTable: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMounted = useRef(true);
  const {
    loading,
    getFilesSuccess,
    files,
    totalFiles,
    s3FileKey
  } = useSelector((state: { file: FileState }) => state.file);

  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [filesData, setFilesData] = useState<File[]>([]);
  const [filters, setFilters] = useState<FileFilters>({
    searchByKeyWords: {
      noOfCustomers: '',
      status: '',
      advertisementFormat: '',
      noOfLetters: '',
      envelopeFormat: '',
      envelopeColor: '',
      noOfEnvelopes: ''
    }
  });
  interface StatusCellRendererParams {
    value: string;
  }

  const getStatusCellRenderer = (params: StatusCellRendererParams) => {
    const { value } = params;

    let statusClass = '';

    switch (value) {
      case 'Printed':
        statusClass = 'status-planned';
        break;
      case 'Not Printed':
        statusClass = 'status-completed';
        break;
      default:
        statusClass = '';
    }

    return <span className={`status-cell ${statusClass}`}>{value}</span>;
  };

  const handleDownloadPdfFile = (fileId: any) => {
    dispatch(UpdateStatusOfFile({ fileId }));
  };

  const actionCellRenderer = (params: { data: File }) => {
    const isFileAvailable = !!params.data.s3FileKey;

    return (
      <Tooltip title={isFileAvailable ? '' : 'File not available'}>
        <BsDownload
          className={`${isFileAvailable ? 'pointer enable' : 'disabled'}`}
          onClick={isFileAvailable ? () => handleDownloadPdfFile(params.data._id) : undefined}
        />
      </Tooltip>
    );
  };

  const getAllFiles = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    dispatch(
      GetAllFiles({
        skip,
        limit,
        sortBy: sortValue || undefined,
        filters: applyFilters
      })
    );
  };

  const columnDefs = [
    {
      headerName: t('tbl_report_date'),
      field: 'fileDownloadedAt',
      headerComponent: FilePageFilterComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'fileDownloadedAt',
        getAllFiles,
        filters,
        setFilters,
        showSearch: false,
        showSort: false,
        showFilter: false,
        showArrow: sortValue?.fileDownloadedAt === 'asc'
          ? 'up'
          : sortValue?.fileDownloadedAt === 'desc'
            ? 'down'
            : null
      },
      pinned: 'left',
      flex: 1,
      minWidth: 130,
      maxWidth: 150,
      cellRenderer: CustomCellRenderer
    },
    {
      headerName: t('tbl_no_of_customers'),
      field: 'noOfCustomers',
      headerComponent: FilesSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'noOfCustomers',
        getAllFiles,
        filters,
        setFilters,
        showFilter: true,
        showArrow: sortValue?.noOfCustomers === 'asc'
          ? 'up'
          : sortValue?.noOfCustomers === 'desc'
            ? 'down'
            : null
      },
      minWidth: 150,
      maxWidth: 160
    },
    {
      headerName: t('tbl_report_status'),
      field: 'status',
      headerComponent: FilePageFilterComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'status',
        getAllFiles,
        filters,
        setFilters,
        options: FILE_REPORT_STATUS,
        showArrow: sortValue?.status === 'asc'
          ? 'up'
          : sortValue?.status === 'desc'
            ? 'down'
            : null
      },
      minWidth: 140,
      maxWidth: 140,
      flex: 1,
      cellRenderer: getStatusCellRenderer
    },
    {
      headerName: t('tbl_advertisement_format'),
      field: 'advertisementFormat',
      headerComponent: FilePageFilterComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'advertisementFormat',
        getAllFiles,
        filters,
        setFilters,
        options: FILE_ADVERTISEMENT_FORMAT,
        showArrow: sortValue?.advertisementFormat === 'asc'
          ? 'up'
          : sortValue?.advertisementFormat === 'desc'
            ? 'down'
            : null
      },
      minWidth: 180,
      flex: 1
    },
    {
      headerName: t('tbl_no_of_letters'),
      field: 'noOfLetters',
      headerComponent: FilesSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'noOfLetters',
        getAllFiles,
        filters,
        setFilters,
        showArrow: sortValue?.noOfLetters === 'asc'
          ? 'up'
          : sortValue?.noOfLetters === 'desc'
            ? 'down'
            : null
      },
      minWidth: 200,
      flex: 1
    },
    {
      headerName: t('tbl_envelope_format'),
      field: 'envelopeFormat',
      headerComponent: FilePageFilterComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'envelopeFormat',
        getAllFiles,
        filters,
        setFilters,
        options: FILE_ENVELOPE_FORMAT,
        showArrow: sortValue?.envelopeFormat === 'asc'
          ? 'up'
          : sortValue?.envelopeFormat === 'desc'
            ? 'down'
            : null
      },
      minWidth: 160,
      maxWidth: 190,
      flex: 1
    },
    {
      headerName: t('tbl_envelope_color'),
      field: 'envelopeColor',
      headerComponent: FilePageFilterComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'envelopeColor',
        getAllFiles,
        filters,
        setFilters,
        options: FILE_ENVELOPE_COLOR,
        showArrow: sortValue?.envelopeColor === 'asc'
          ? 'up'
          : sortValue?.envelopeColor === 'desc'
            ? 'down'
            : null
      },
      minWidth: 150,
      maxWidth: 180,
      flex: 1
    },
    {
      headerName: t('tbl_no_of_envelopes'),
      field: 'noOfEnvelopes',
      headerComponent: FilesSearchComponent,
      headerComponentParams: {
        setSortValue,
        fieldName: 'noOfEnvelopes',
        getAllFiles,
        filters,
        setFilters,
        showArrow: sortValue?.noOfEnvelopes === 'asc'
          ? 'up'
          : sortValue?.noOfEnvelopes === 'desc'
            ? 'down'
            : null
      },
      minWidth: 160,
      maxWidth: 170,
      flex: 1
    },
    {
      headerName: t('tbl_actions'),
      field: 'actions',
      pinned: 'right',
      flex: 1,
      cellRenderer: actionCellRenderer,
      maxWidth: 100
    }
  ];

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  useEffect(() => {
    if (getFilesSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetFileState({
        field: 'getFilesSuccess',
        value: false
      }));
    }
  }, [getFilesSuccess]);

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    getAllFiles({ applyFilters: filters });
  }, [pageLimit, pageNumber, sortValue]);

  useEffect(() => {
    if (files?.length) {
      const processedTemplates = files.map((file) => ({
        ...file,
        fileDownloadedAt: file?.fileDownloadedAt
          ? moment(file?.fileDownloadedAt).format('DD MMMM YYYY') : 'N/A'
      }));
      setFilesData(processedTemplates);
    } else {
      setFilesData([]);
    }
  }, [files]);

  useEffect(() => {
    if (s3FileKey) {
      const fileUrl = GetS3ImageUrl({
        bucketName: 'files',
        key: s3FileKey
      });

      if (fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = s3FileKey;
        link.click();

        dispatch(SetFileState({
          field: 's3FileKey',
          value: ''
        }));
      }
    }
  }, [s3FileKey]);

  return (
    <>
      {loading ? <Loader /> : null}
      <CampaignsWrapper>
        <div className="content-header">
          <h2 className="heading">{t('files')}</h2>
        </div>
        <Table
          rowData={filesData}
          columnDefs={columnDefs}
          sortable
          resizable
          editable
          filter
          height="164px"
          rowHeight={36}
          headerHeight={40}
          className="custom-table"
          isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        />
        <Pagination
          totalPages={totalFiles}
          currentPage={pageNumber}
          onChange={onPageChange}
          pageSize={pageLimit}
          pageSizes={PAGE_SIZES}
        />
      </CampaignsWrapper>
    </>
  );
};

export default FilesTable;
