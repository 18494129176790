import React, {
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/modal';

import HandWritten from '../../../assets/images/handwritten-1.jpg';
import Printed from '../../../assets/images/printed-1.jpg';

import {
  AddTemplateSheet
} from '../../../redux/types/template';

import {
  HAND_WRITTEN_INITIAL_OBJECT,
  LETTER_TYPE_VALUES,
  PRINTED_INITIAL_OBJECT
} from '../../../constants';

interface AddSheetModalProps {
  setTemplateSheets: (value: any) => void;
  onCancel?: () => void;
  open?: boolean;
  title?: string;
  setSelectedSheet: (value: number) => void;
  setAddSheetModal: (value: boolean) => void;
}

const AddSheetModal: React.FC<AddSheetModalProps> = ({
  onCancel,
  open,
  title,
  setSelectedSheet,
  setTemplateSheets,
  setAddSheetModal
}) => {
  const { t } = useTranslation();

  const sheetData = [{
    image: HandWritten,
    title: t('handwritten')
  }, {
    image: Printed,
    title: t('pdf_print')
  }];

  const [addSheet, setAddSheet] = useState(sheetData[0].title);

  const insertTemplateSheet = (template: AddTemplateSheet) => {
    setTemplateSheets((prevTemplateSheets: AddTemplateSheet[]) => {
      const newTemplateSheets = [...prevTemplateSheets, template];
      const addedIndex = newTemplateSheets.length - 1;
      setSelectedSheet(addedIndex);
      return newTemplateSheets;
    });
    setAddSheetModal(false);
  };

  const handleClick = () => {
    if (addSheet === LETTER_TYPE_VALUES.HANDWRITTEN) {
      insertTemplateSheet(HAND_WRITTEN_INITIAL_OBJECT);
    } else if (addSheet === LETTER_TYPE_VALUES.PRINTED) {
      insertTemplateSheet(PRINTED_INITIAL_OBJECT);
    }
  };

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={onCancel}
      saveText={t('add_sheet')}
      width={548}
      saveButtonWidth="115px"
      open={open}
      title={title}
      onSubmitClick={() => handleClick()}
    >
      <div className="d-flex gap-18 align-items-start mb-4">
        {
          sheetData.map((letter, index) => (
            <div
              role="button"
              tabIndex={0}
              key={index}
              className={
                (addSheet === letter.title
                  || (addSheet === 'Printed'
                    && letter.title === t('pdf_print')))
                  ? 'add-sheet active'
                  : 'add-sheet letter-box'
              }
              onClick={() => setAddSheet(letter.title === t('handwritten')
                ? LETTER_TYPE_VALUES.HANDWRITTEN : LETTER_TYPE_VALUES.PRINTED)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setAddSheet(letter.title === t('handwritten')
                    ? LETTER_TYPE_VALUES.HANDWRITTEN : LETTER_TYPE_VALUES.PRINTED);
                }
              }}
            >
              <img className="type" src={letter.image} alt={letter.title} />
              <div className="d-flex bottom-space">
                <h4>{letter.title}</h4>
              </div>
            </div>
          ))
        }
      </div>
    </Modal>
  );
};

export default AddSheetModal;
