import React, {
  ReactNode
} from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '../button/index';
import Modal from '../modal';
import WarningIcon from '../../assets/icons/warning.svg';

import WarningModalWrapper from './warning.style';

import Loader from '../loader';

interface WarningModalProps {
  loading?: boolean;
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  desc?: string;
  heading?: string;
  icon?: ReactNode;
  saveText?: any
  modalFor?: string;
  cancelText?: string;
  disableConfirm?: boolean;
}

const WarningModal: React.FC<WarningModalProps> = ({
  loading,
  open,
  onConfirm,
  onCancel,
  desc,
  heading,
  icon,
  saveText,
  cancelText,
  modalFor,
  disableConfirm = false
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} width="364px">
      <WarningModalWrapper>
        {icon || <img src={WarningIcon} alt={t('no_icon')} />}
        <h3>{heading || t('warning')}</h3>
        {desc && <span className="warning-desc">{desc}</span>}
        <div className="margin-top">
          {loading && <Loader />}
          <Space size="middle">
            {onCancel
              && (
                <Button
                  text={!modalFor ? (cancelText || t('cancel')) : t('ok')}
                  type="default"
                  onClick={onCancel}
                  width="114px"
                />
              )}
            {!modalFor && (
              <Button
                text={saveText || t('confirm')}
                type="primary"
                width={saveText === t('change_to_branding') ? '135px' : '119px'}
                onClick={onConfirm}
                disabled={disableConfirm}
              />
            )}
          </Space>
        </div>
      </WarningModalWrapper>
    </Modal>
  );
};

export default WarningModal;
